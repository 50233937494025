import { FormPolygonDrawings } from '@/shared/ui/FormPolygonDrawing'
import { coordsArrayToLatLngLiterals, latLngToPolygonCoordsArray } from '@/shared/ui/Map'
import { useMapUtils } from '@/shared/ui/Map/hooks/useMapUtils'
import { Field, FieldProps, useFormikContext } from 'formik'
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { CreateBuildingFormState } from '../model/types'
import { isNullOrUndefined } from '@/shared/lib/utils'
import { TextField } from '@/shared/ui/FormFields'
import { ResidentialListItem } from '@/shared/api/topli/TopliApi'

type FieldFormPolygonDrawingProps = {
  name: string
  residentials?: ResidentialListItem[]
  className?: string
  disabled?: boolean
}

export const FieldFormPolygonDrawing: FC<FieldFormPolygonDrawingProps> = props => {
  const { name, residentials, className, disabled } = props

  const [loc, setLoc] = useState<google.maps.LatLngLiteral | null>(null)
  const { values, setFieldValue } = useFormikContext<CreateBuildingFormState>()

  const residentialId = values?.residential_id
  const polygon = values?.polygon

  const clearPolygon = () => {
    setFieldValue('polygon', [])
    setFieldValue('area', 0)
  }

  useEffect(() => {
    clearPolygon()
  }, [residentialId])

  useEffect(() => {
    if (residentialId === null || isNullOrUndefined(residentialId)) {
      setLoc(null)
      return
    }

    const loc = residentials?.find(item => item.id === residentialId)?.address.loc
    setLoc(loc ? { lat: loc[0], lng: loc[1] } : null)
  }, [residentialId, residentials])

  const polygonLatLngLiterals = useMemo(
    () => (polygon === null ? null : coordsArrayToLatLngLiterals(polygon)),
    [polygon],
  )

  const { getPolygonArea, getPolygonPaths } = useMapUtils()
  const handleCreateEditPolygon = useCallback(
    (_polygon: google.maps.Polygon) => {
      const polygon = latLngToPolygonCoordsArray(getPolygonPaths(_polygon))

      const area = getPolygonArea(_polygon)
      if (area !== null) {
        setFieldValue('area', Math.round(area))
      }
      setFieldValue('polygon', polygon)
    },
    [getPolygonPaths, getPolygonArea, setFieldValue],
  )

  return (
    <Field name={name}>
      {({ field }: FieldProps) => (
        <div className={className}>
          <FormPolygonDrawings
            onPolygonCreate={handleCreateEditPolygon}
            onPolygonEdit={handleCreateEditPolygon}
            polygonPaths={polygonLatLngLiterals}
            onClearPolygon={clearPolygon}
            addressLoc={loc}
            disabled={disabled}
          />
          <TextField disabled {...field} placeholder='Area, m^2' className='flex flex-col w-full' />
        </div>
      )}
    </Field>
  )
}

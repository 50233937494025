import * as Yup from 'yup'
import { EditUnitAmenityFormState } from '../model/types'
import { LanguageCode, LanguageLabel } from '@/widgets/LanguageSelect/lib/consts'
import { EditUnitAmenityEN } from '../ui/Tabs/EditUnitAmenityEN'
import { EditUnitAmenityRU } from '../ui/Tabs/EditUnitAmenityRU'
import { EditUnitAmenityTR } from '../ui/Tabs/EditUnitAmenityTR'
import { TFunction } from 'i18next'

export const editUnitAmenityFormScheme = (t: TFunction) => {
  return Yup.object({
    translations: Yup.object({
      tr: Yup.object({
        name: Yup.string()
          .max(127, t('common:validation_messages.max', { count: 127 }))
          .required(t('common:validation_messages.required')),
      }),
      en: Yup.object({
        name: Yup.string().max(127, t('common:validation_messages.max', { count: 127 })),
      }),
      ru: Yup.object({
        name: Yup.string().max(127, t('common:validation_messages.max', { count: 127 })),
      }),
    }),
  })
}

export const editUnitAmenityFormDefault: EditUnitAmenityFormState = {
  id: 0,
  has_ai_translation: false,
  pic_url: '',
  translations: {
    tr: { name: '', id: null, ai_translated: false },
    en: { name: '', id: null, ai_translated: false },
    ru: { name: '', id: null, ai_translated: false },
  },
}

export const editAmenityFormTabsConfig = (isLoadingEditUnitAmenity: boolean) => [
  {
    title: LanguageLabel.tr,
    value: LanguageCode.tr,
    component: EditUnitAmenityTR,
    props: {
      isLoadingEditUnitAmenity,
    },
  },
  {
    title: LanguageLabel.en,
    value: LanguageCode.en,
    component: EditUnitAmenityEN,
    props: {
      isLoadingEditUnitAmenity,
    },
  },
  {
    title: LanguageLabel.ru,
    value: LanguageCode.ru,
    component: EditUnitAmenityRU,
    props: {
      isLoadingEditUnitAmenity,
    },
  },
]

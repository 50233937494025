import { FC } from 'react'
import { TortoiseContribPydanticCreatorModelsCompanyLeaf } from '@/shared/api/topli/generated/Api'
import { convertPhoneNumberToString, getCurrentTranslation } from '@/shared/lib/utils'
import { useLanguageStore } from '@/widgets/LanguageSelect/model/store'

interface CompanyCellProps {
  company: TortoiseContribPydanticCreatorModelsCompanyLeaf
}

const CompanyCell: FC<CompanyCellProps> = ({ company }) => {
  const { language } = useLanguageStore()
  return (
    <div className='flex flex-col w-full'>
      <div className='text-sm font-semibold mb-1'>
        {getCurrentTranslation(company, language, 'name')}
      </div>
      <div className='text-xs'>{company.email}</div>
      <div className='text-xs'>{convertPhoneNumberToString(company.phone)}</div>
    </div>
  )
}

export default CompanyCell

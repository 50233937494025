import { type FC } from 'react'
import { Form, Formik } from 'formik'
import { useCreateClientForm } from '../lib/use-create-client-form'
import { SelectField, TextAreaField } from '@/shared/ui/FormFields'
import { Button } from '@/shared/ui/Button/Button'
import { Loader } from '@mantine/core'
import { createClientFormSchema } from '../lib/consts'
import { useTranslatedEnums } from '@/shared/helpers/apiEnumTypesOptions'
import { useTranslation } from 'react-i18next'
import { InputAutocompleteField } from '@/shared/ui/Input/InputAutocompleteField'

type CreateClientFormProps = {
  onCloseModal: () => void
}

export const CreateClientForm: FC<CreateClientFormProps> = props => {
  const { onCloseModal } = props
  const { orderStatusOptions } = useTranslatedEnums()

  const {
    initialValues,
    userOptions,
    isLoadingCreateClient,
    submitForm,
    isLoadingUsers,
    residentialsOptions,
    isLoadingContacts,
    contactOptions,
    isLoadingResidentials,
    searchUser,
    searchContact,
    setSearchUser,
    setSearchContact,
    createClientFormDefault,
    searchResidential,
    setSearchResidential,
  } = useCreateClientForm(onCloseModal)
  const { t } = useTranslation(['client'])

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submitForm}
      validationSchema={createClientFormSchema(t)}
      enableReinitialize
    >
      <Form className='pt-4'>
        <div className='flex flex-row w-full space-x-4'>
          <InputAutocompleteField
            name='created_by_id'
            label={t('client:agent')}
            onChange={setSearchUser}
            value={searchUser}
            className='flex flex-col w-full mb-4'
            data={userOptions}
            disabled={isLoadingCreateClient}
            isLoading={isLoadingUsers}
            initialValue={createClientFormDefault.created_by_id}
            required
          />
          <InputAutocompleteField
            name='contact_id'
            label={t('client:client')}
            onChange={setSearchContact}
            value={searchContact}
            className='flex flex-col w-full mb-4'
            data={contactOptions}
            disabled={isLoadingCreateClient}
            isLoading={isLoadingContacts}
            initialValue={createClientFormDefault.contact_id}
            required
          />
        </div>
        <div className='flex flex-row w-full space-x-4'>
          <SelectField
            name='status'
            label={t('client:order_status')}
            options={orderStatusOptions}
            className='flex flex-col w-full mb-4'
            disabled={isLoadingCreateClient}
          />
        </div>
        <InputAutocompleteField
          name='residential_id'
          label={t('client:residential')}
          onChange={setSearchResidential}
          value={searchResidential}
          className='flex flex-col w-full mb-4'
          data={residentialsOptions}
          disabled={isLoadingCreateClient}
          isLoading={isLoadingResidentials}
          initialValue={createClientFormDefault.residential_id}
          required
        />
        <TextAreaField
          name='comment'
          label={t('client:comment')}
          className='flex flex-col w-full mb-4'
          disabled={isLoadingCreateClient}
        />
        <div className='flex flex-row w-full'>
          <Button type='submit' size='md' color='base' disabled={isLoadingCreateClient}>
            {isLoadingCreateClient ? (
              <Loader size='xs' color='white' />
            ) : (
              t('client:buttons.add_client')
            )}
          </Button>
        </div>
      </Form>
    </Formik>
  )
}

import { useEffect, useState } from 'react'
import { editUnitAmenityFormDefault } from './consts'
import { EditUnitAmenityFormState } from '../model/types'
import { FormikConfig } from 'formik'
import { notification } from '@/shared/helpers/notificationMessages'
import { useEditUnitAmenityApi } from '../api/edit-unit-amenity-api'
import { errorHandler } from '@/shared/lib/errorHandler'
import { useUnitAmenityById } from '@/entities/UnitAmenity/api/unit-amenity-api'
import { useTranslation } from 'react-i18next'
import { hasAiTranslation, matchTranslations } from '@/shared/lib/utils'
import { EditTranslations } from '@/shared/types/ContentTranslations'
import { updateTranslationsForEditing } from '@/shared/lib/updateTranslationsForEditing'

export const useEditUnitAmenityForm = (onCloseModal: () => void, selectUnitAmenityId?: number) => {
  const [initialValues, setInitialValues] = useState<EditUnitAmenityFormState>(
    () => editUnitAmenityFormDefault,
  )
  const [originalTranslations, setOriginalTranslations] = useState<EditTranslations | null>(null)

  const { t } = useTranslation(['common'])

  const {
    data: currentUnitAmenity,
    isLoading: isLoadingUnitAmenity,
    isError: isErrorUnitAmenity,
  } = useUnitAmenityById(selectUnitAmenityId)

  const {
    mutateAsync,
    isPending: isLoadingEditUnitAmenity,
    isSuccess: isSuccessEditUnitAmenity,
  } = useEditUnitAmenityApi()

  const submitForm: FormikConfig<EditUnitAmenityFormState>['onSubmit'] = async values => {
    const { translations, ...restValues } = values
    const updatedTranslations = updateTranslationsForEditing(translations, originalTranslations)
    const hasAiTranslationFlag = hasAiTranslation(updatedTranslations)

    const updatedValues = {
      ...restValues,
      has_ai_translation: hasAiTranslationFlag,
      translations: updatedTranslations,
    }

    try {
      await mutateAsync(updatedValues)
      onCloseModal()
    } catch (error) {
      errorHandler(error, t('common:notification_messages.edit_amenity_error'))
    }
  }

  useEffect(() => {
    if (currentUnitAmenity) {
      setInitialValues(() => {
        const { id, translations, has_ai_translation, pic_url } = currentUnitAmenity
        const matchedTranslations = matchTranslations(translations, initialValues.translations)
        setOriginalTranslations({ ...matchedTranslations })
        return {
          id,
          pic_url: pic_url ?? '',
          translations: matchedTranslations,
          has_ai_translation,
        }
      })
    }
  }, [currentUnitAmenity])

  useEffect(() => {
    if (isSuccessEditUnitAmenity) {
      notification.success(
        t('common:notification_titles.success'),
        t('common:notification_messages.edit_amenity_success'),
      )
    }
  }, [isSuccessEditUnitAmenity])

  return {
    initialValues,
    isLoadingEditUnitAmenity,
    isLoadingUnitAmenity,
    isErrorUnitAmenity,
    submitForm,
  }
}

import { useQuery } from '@tanstack/react-query'
import { topliApi } from '@/shared/api/topli/TopliApi'
import { ResidentialsRequestParams } from '../model/types'

export const residentialsQueryKey = 'residentials'
export const currentResidentialByIdQueryKey = 'currentResidentialById'
export const residentialsByPageQueryKey = 'residentialsByPage'
export const residentialAmenitiesByPageQueryKey = 'residentialAmenitiesByPage'
export const searchResidentialsQueryKey = 'searchBuildings'

export const fetchResidentials = async (language: string) => {
  const response = await topliApi.residential.list({ language })
  return response
}

export const fetchResidentialsByPage = async (
  sorting: string | null,
  limit: number,
  offset: number,
  search: string,
  language: string,
) => {
  const requestParams: ResidentialsRequestParams = {
    limit,
    offset,
    sort: sorting,
    language,
  }

  if (search.length) {
    requestParams.translations__name__icontains = search
    requestParams.translations__language__icontains = language
  }

  const response = await topliApi.residential.list(requestParams)
  return response
}

export const fetchResidentialById = async (id?: number) => {
  if (!id) return
  const response = await topliApi.residential.get(id)
  return response
}

export const searchResidentialsByText = async (language: string, search?: string) => {
  if (!search) return
  const response = await topliApi.residential.list({
    translations__name__icontains: search,
    translations__language__icontains: language,
    language: language,
  })
  return response
}

export const fetchResidentialAmenitiesByPage = async (
  limit: number,
  offset: number,
  language: string,
) => {
  const response = await topliApi.residentialAmenity.list({ limit, offset, language })
  return response
}

export const useSearchResidentialsByText = (language: string, search?: string) => {
  const data = useQuery({
    queryKey: [searchResidentialsQueryKey, search, language],
    queryFn: () => searchResidentialsByText(language, search),
    enabled: !!search,
  })
  return data
}

export const useResidentials = (language: string) =>
  useQuery({
    queryKey: [residentialsQueryKey, language],
    queryFn: () => fetchResidentials(language),
  })

export const useResidentialsByPage = (
  sorting: string | null,
  limit: number,
  page: number,
  search: string,
  language: string,
) =>
  useQuery({
    queryKey: [residentialsByPageQueryKey, sorting, page, limit, search || '', language],
    queryFn: () => fetchResidentialsByPage(sorting, limit, page, search || '', language),
  })

export const useResidentialById = (id?: number, language?: string) =>
  useQuery({
    queryKey: [currentResidentialByIdQueryKey, id, language],
    queryFn: () => fetchResidentialById(id),
    enabled: !!id,
  })

export const useResidentialAmenitiesByPage = (limit: number, page: number, language: string) =>
  useQuery({
    queryKey: [residentialAmenitiesByPageQueryKey, page, language],
    queryFn: () => fetchResidentialAmenitiesByPage(limit, page, language),
  })

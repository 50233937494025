import { type CreateClientFormState } from '../model/types'
import * as Yup from 'yup'
import { OrderStatus } from '@/shared/api/topli/TopliApi'
import { TFunction } from 'i18next'

export const createClientFormDefault: CreateClientFormState = {
  status: null,
  comment: '',
  contact_id: 0,
  created_by_id: 0,
  residential_id: 0,
}

export const createClientFormSchema = (t: TFunction) => {
  return Yup.object().shape({
    comment: Yup.string().max(127, t('common:validation_messages.max', { count: 127 })),
    status: Yup.mixed<OrderStatus>()
      .oneOf(Object.values(OrderStatus) as number[], t('common:validation_messages.required'))
      .required(t('common:validation_messages.required')),
    contact_id: Yup.number()
      .min(1, t('common:validation_messages.required'))
      .required(t('common:validation_messages.required')),
    created_by_id: Yup.number()
      .min(1, t('common:validation_messages.required'))
      .required(t('common:validation_messages.required')),
    residential_id: Yup.number()
      .min(1, t('common:validation_messages.required'))
      .required(t('common:validation_messages.required')),
  })
}

import { useCallback, useEffect, useMemo, useState } from 'react'
import { editResidentialFormDefault } from './consts'
import { EditResidentialFormState } from '../model/types'
import { FormikConfig, FormikErrors } from 'formik'
import { notification } from '@/shared/helpers/notificationMessages'
import {
  createAutocompleteOptions,
  createTranslatedSelectOptions,
  getCurrentTranslation,
  hasAiTranslation,
  matchTranslations,
} from '@/shared/lib/utils'
import { useUserList } from '@/entities/User'
import { useAddressesWithoutResidentials } from '@/entities/Address'
import { useEditResidentialApi } from '../api/edit-residential-form-api'
import { errorHandler } from '@/shared/lib/errorHandler'
import { useResidentialAmenitiesByPage, useResidentialById } from '@/entities/Residential'
import { useCreateAddressApi } from '@/features/Address/CreateAddressForm'
import { CreateAddressFormState } from '@/features/Address/CreateAddressForm/model/types'
import { createAddressFormDefault } from '@/features/Address/CreateAddressForm/lib/consts'
import { Coords } from 'google-map-react'
import { AddressInfo, useAddress } from '@/shared/ui/Map'
import { getExistedProperties } from '@/shared/lib/getExistedProperties'
import { useTranslation } from 'react-i18next'
import { useLanguageStore } from '@/widgets/LanguageSelect/model/store'
import { EditTranslations } from '@/shared/types/ContentTranslations'
import { updateTranslationsForEditing } from '@/shared/lib/updateTranslationsForEditing'
import { useDebounce } from '@/shared/lib/useDebounce'
import { useSearchCompaniesByName } from '@/entities/Company/api/company-api'
import { TortoiseContribPydanticCreatorModelsAddressLeaf } from '@/shared/api/topli/TopliApi'

export const useEditResidentialForm = (currentResidentialId: number, onCloseModal: () => void) => {
  const { getAddressInfoByCoords } = useAddress()
  const { language } = useLanguageStore()
  const { data: managers, isLoading: isLoadingManagers } = useUserList({ is_manager: true })
  const { data: contentManagers, isLoading: isLoadingContentManagers } = useUserList({
    is_content_manager: true,
  })
  const { data: rawAddresses, isLoading: isLoadingAddresses } = useAddressesWithoutResidentials()
  const [addresses, setAddresses] = useState<TortoiseContribPydanticCreatorModelsAddressLeaf[]>()
  const { data: amenities, isLoading: isLoadingAmenities } = useResidentialAmenitiesByPage(
    1000,
    0,
    language,
  )
  const [initialValues, setInitialValues] = useState<EditResidentialFormState>(
    () => editResidentialFormDefault,
  )
  const [originalTranslations, setOriginalTranslations] = useState<EditTranslations | null>(null)
  const [autoSettedFields, setAutoSettedFields] = useState<(keyof CreateAddressFormState)[]>([])
  const [newAddress, setNewAddress] = useState<CreateAddressFormState>(
    () => createAddressFormDefault,
  )
  const [coords, setCoords] = useState<Coords | null>(null)
  const { t } = useTranslation(['common'])

  const [isOpen, setIsOpen] = useState(false)
  const [searchCompany, setSearchCompany] = useState<string>('')
  const debouncedSearchValue = useDebounce(searchCompany, 500)
  const { data: companies, isLoading: isLoadingCompanies } = useSearchCompaniesByName(
    language,
    debouncedSearchValue,
  )

  const {
    data: currentResidential,
    isLoading: isLoadingResidential,
    isError: isErrorResidential,
  } = useResidentialById(currentResidentialId)

  const {
    mutateAsync,
    isPending: isLoadingEditResidential,
    isSuccess: isSuccessEditResidential,
  } = useEditResidentialApi()

  const {
    mutateAsync: mutateAsyncCreateAddress,
    isPending: isLoadingCreateAddress,
    isSuccess: isSuccessCreateAddress,
    data: newAddressData,
  } = useCreateAddressApi()

  useEffect(() => {
    if (rawAddresses?.data && currentResidential?.address) {
      const updatedAddresses = [currentResidential.address, ...rawAddresses.data]
      setAddresses(updatedAddresses)
    } else {
      setAddresses(rawAddresses?.data)
    }
  }, [rawAddresses, currentResidential?.address])

  const submitForm: FormikConfig<EditResidentialFormState>['onSubmit'] = async values => {
    const { translations, ...restValues } = values
    const updatedTranslations = updateTranslationsForEditing(translations, originalTranslations)
    const hasAiTranslationFlag = hasAiTranslation(updatedTranslations)

    const updatedValues = {
      ...restValues,
      has_ai_translation: hasAiTranslationFlag,
      translations: updatedTranslations,
    }

    try {
      await mutateAsync(updatedValues)
      onCloseModal()
    } catch (error) {
      errorHandler(error, t('common:notification_messages.edit_residential_error'))
    }
  }

  const handleSaveAddress = async (
    cb: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined,
    ) => Promise<void | FormikErrors<any>>,
  ) => {
    try {
      const newAddressResponse = await mutateAsyncCreateAddress(newAddress)
      cb('address_id', newAddressResponse?.id)
      setIsOpen(false)
    } catch (error) {
      errorHandler(error)
    }
  }

  useEffect(() => {
    if (currentResidential) {
      setInitialValues(() => {
        const {
          video_url,
          delivery_date,
          clas,
          activity,
          done,
          has_ai_translation,
          translations,
          hide_price,
          address,
          company,
          car_unitcount,
          unitcount,
          area,
          id,
          parking_type,
          polygon,
          purpose,
          project_url,
          googledrive_company_url,
          googledrive_url,
          content_manager,
          manager,
          fee,
          amenities,
          citizenship,
          title_deed,
          downpayment,
          duration,
          vat,
        } = currentResidential
        setSearchCompany(getCurrentTranslation(company, language, 'name'))
        const matchedTranslations = matchTranslations(translations, initialValues.translations)
        setOriginalTranslations({ ...matchedTranslations })
        return {
          delivery_date: delivery_date ? new Date(delivery_date as string) : null,
          clas,
          activity,
          video_url,
          has_ai_translation,
          translations: matchedTranslations,
          done,
          hide_price,
          address_id: address.id,
          company_id: company.id,
          car_unitcount: car_unitcount ?? 0,
          unitcount,
          area,
          id,
          parking_type,
          polygon,
          purpose,
          googledrive_company_url,
          googledrive_url,
          content_manager_id: content_manager.id,
          manager_id: manager.id,
          fee: fee ?? 0,
          amenities: (amenities || []).map(amenity => amenity.id),
          project_url: project_url || '',
          citizenship,
          title_deed,
          vat,
          downpayment,
          duration,
        }
      })
    }
  }, [currentResidential])

  useEffect(() => {
    if (isSuccessEditResidential) {
      notification.success(
        t('common:notification_titles.success'),
        t('common:notification_messages.edit_residential_success'),
      )
    }
  }, [isSuccessEditResidential])

  useEffect(() => {
    if (coords === null) return

    const setAddressFormData = async (_coords: Coords) => {
      const address = await getAddressInfoByCoords(_coords)
      if (address === null) {
        setNewAddress(createAddressFormDefault)
        return
      }

      const addressInfo: Partial<AddressInfo> = {
        ...address,
        gid: address?.place_id ?? undefined,
      }
      setNewAddress(prevValues => {
        const {
          administrative_area_level_1,
          administrative_area_level_2,
          formatted,
          country,
          gid,
          locality,
          plus_code,
          postal_code,
          route,
          street_number,
        } = addressInfo
        return {
          ...prevValues,
          administrative_area_level_1: administrative_area_level_1 ?? '',
          administrative_area_level_2: administrative_area_level_2 ?? '',
          formatted,
          country: country ?? '',
          gid,
          locality: locality ?? '',
          plus_code: plus_code ?? '',
          postal_code: postal_code ?? '',
          route: route ?? '',
          street_number: street_number ?? '',
          lat: _coords.lat,
          lng: _coords.lng,
        }
      })
      setAutoSettedFields(getExistedProperties(addressInfo as CreateAddressFormState))
    }
    setAddressFormData(coords)
  }, [coords, getAddressInfoByCoords])

  const handleSetLoc = useCallback((coords: Coords | null) => {
    if (coords === null) return
    setCoords({ lat: coords.lat, lng: coords.lng })
  }, [])

  const handleCreateNewAddress = () => setIsOpen(true)

  const companiesOptions = createAutocompleteOptions(companies?.data, language)

  const managerOptions = (managers?.data || []).map(user => ({
    value: user.id,
    label: user.fullname as string,
  }))

  const contentManagerOptions = (contentManagers?.data || []).map(user => ({
    value: user.id,
    label: user.fullname as string,
  }))

  const addressOptions = (addresses || []).map(item => ({
    value: item.id,
    label: item.formatted,
  }))

  const amenitiesOptions = createTranslatedSelectOptions(amenities?.data)

  return {
    initialValues,
    companiesOptions,
    amenitiesOptions,
    managerOptions,
    contentManagerOptions,
    addressOptions,
    addresses,
    isLoadingAddresses,
    isLoadingCompanies,
    isLoadingManagers,
    isLoadingContentManagers,
    isLoadingEditResidential,
    isLoadingAmenities,
    isLoadingResidential,
    isErrorResidential,
    isLoadingCreateAddress,
    coords,
    isOpen,
    submitForm,
    handleSetLoc,
    setIsOpen,
    handleCreateNewAddress,
    handleSaveAddress,
    searchCompany,
    setSearchCompany,
    editResidentialFormDefault,
  }
}

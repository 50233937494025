import { useQuery } from '@tanstack/react-query'
import { topliApi } from '@/shared/api/topli/TopliApi'
import { UserFilters } from '../model/types'

export const selectUserByIdQueryKey = ['selectUserById']
export const usersQueryKey = ['users']
export const usersByPageQueryKey = 'usersByPage'
export const searchUsersQueryKey = 'searchUsers'

export const fetchUserById = async (id?: number) => {
  if (!id) throw new Error('No user')
  const response = await topliApi.user.get(id)
  return response
}

export const fetchUserList = async (filters: UserFilters = {}) => {
  const response = await topliApi.user.list(filters)
  return response
}

export const fetchUserListByPage = async (
  sorting: string | null,
  limit: number,
  offset: number,
  search: string,
) => {
  const response = await topliApi.user.list({
    limit,
    offset,
    sort: sorting,
    fullname__icontains: search,
  })
  return response
}

export const searchUsersByName = async (search?: string) => {
  if (!search) return
  const response = await topliApi.user.list({
    fullname__icontains: search,
  })
  return response
}

export const useSearchUsersByName = (search?: string) => {
  const data = useQuery({
    queryKey: [searchUsersQueryKey, search],
    queryFn: () => searchUsersByName(search),
    enabled: !!search,
  })
  return data
}

export const useUserById = (id?: number) =>
  useQuery({
    queryKey: [selectUserByIdQueryKey, id],
    queryFn: () => fetchUserById(id),
    staleTime: 0,
  })

export const useUserList = (filters: UserFilters = {}) =>
  useQuery({
    queryKey: [usersQueryKey, filters],
    queryFn: () => fetchUserList(filters),
  })

export const useUsersByPage = (
  sorting: string | null,
  limit: number,
  page: number,
  search: string,
) =>
  useQuery({
    queryKey: [usersByPageQueryKey, sorting, page, limit, search || ''],
    queryFn: () => fetchUserListByPage(sorting, limit, page, search),
  })

import { ImagableType, Residential } from '@/shared/api/topli/TopliApi'
import { Tabs, Title } from '@mantine/core'
import { type FC } from 'react'
import { PlusCircleIcon } from 'lucide-react'
import { useResidentialBuildings } from '../lib/use-residential-buildings'
import { UnitsInBuildingTable } from './UnitsInBuildingTable'
import { Button } from '@/shared/ui/Button/Button'
import { CreateUnitModal } from '@/widgets/Listings/UnitsView/ui/Modals/CreateUnitModal'
import { EditUnitModal } from '@/widgets/Listings/UnitsView/ui/Modals/EditUnitModal'
import { DeleteUnitAlert } from '@/features/Unit/DeleteUnitAlert'
import { CreateBuildingModal } from '@/widgets/Listings/BuildingsView/ui/Modals/CreateBuildingModal'
import { UnitTableSkeleton } from './Skeletons/UnitTableSkeleton'
import { ResidentialBuildingCard } from './ResidentialBuildingCard'
import { EditBuildingModal } from '@/widgets/Listings/BuildingsView/ui/Modals/EditBuildingModal'
import { DeleteBuildingAlert } from '@/features/Building/DeleteBuildingAlert'
import { FullTextDescriptionModal } from '@/shared/ui/ModalDialogs'
import { useResidentialBuildingsModals } from '../lib/use-residential-buildings-modals'
import {
  EditUploadedPhotosModal,
  PhotoOwnerCaption,
  PhotosCarousel,
  currentPhotosByEntityIdQueryKey,
} from '@/entities/Photos'
import { UploadPhotosForm } from '@/features/Photos/UploadPhotosForm'
import { currentBuildingByIdQueryKey, buildingsByPageQueryKey } from '@/entities/Building'
import { currentUnitByIdQueryKey, unitListsByBuildingIdQueryKey } from '@/entities/Unit'
import { EditPhotoCropModal } from '@/features/Photos/EditPhotoCropModal'
import { DeletePhotoAlert } from '@/features/Photos/DeletePhotoAlert'
import { useTranslation } from 'react-i18next'
import { useLanguageStore } from '@/widgets/LanguageSelect/model/store'
import { getCurrentTranslation } from '@/shared/lib/utils'

type ResidentialBuildingsProps = {
  residential: Residential
}

export const ResidentialBuildings: FC<ResidentialBuildingsProps> = props => {
  const { residential } = props
  const { activeTab, currentBuilding, isLoading, setActiveTab } =
    useResidentialBuildings(residential)
  const { t } = useTranslation(['common', 'building', 'residential', 'unit'])

  const {
    showMoreDialogOpen,
    createDialogOpen,
    createBuildingDialogOpen,
    currentItem,
    selectPhotoId,
    editBuildingDialogOpen,
    editDialogOpen,
    copyDialogOpen,
    openDeleteBuildingDialog,
    openUploadedPhotosDialog,
    openUploadedBuildingPhotoDialog,
    openEditCropPhotoDialog,
    openDeletePhotoDialog,
    deleteDialogOpen,
    showPhotosDialogOpen,
    handleCloseModal,
    handleOpenModal,
    handleOpenPhotos,
    handleOpenDeleteModal,
    handleOpenEditModal,
    handleOpenCopyModal,
    handleOpenShowMoreModal,
    handleOpenUploadedUnitPhotos,
    handleOpenEditBuildingModal,
    handleOpenCreateBuildingModal,
    handleOpenDeleteBuildingModal,
    handleOpenCreateUnitModal,
    handleOpenUnitPhotos,
    handleOpenUploadedBuildingPhotos,
    handleDeletePhoto,
    handleOpenEditCropPhoto,
    handleCloseCropModal,
    lastImageCropTimestamp,
    setLastImageCropTimestamp,
  } = useResidentialBuildingsModals()
  const { language } = useLanguageStore()

  return (
    <>
      <div className='flex flex-row w-full px-8 py-4 justify-between items-center'>
        <Title order={2} className='text-2xl text-left font-body'>
          {t('residential:buildings')}
        </Title>
        <Button
          variant='primary'
          color='base'
          size='md'
          onClick={handleOpenCreateBuildingModal}
          leftIcon={<PlusCircleIcon size={16} />}
        >
          {t('building:buttons.new_building')}
        </Button>
      </div>
      <div className='flex flex-col md:w-full w-[calc(100%+2.5rem)] md:mx-0 mx-[-1.25rem] bg-white rounded-[1.25rem] shadow-navbar pt-4 pb-8 px-8 gap-y-4'>
        {residential.buildings.length > 0 ? (
          <>
            <Tabs
              value={activeTab}
              onTabChange={setActiveTab}
              variant='pills'
              classNames={{
                tabsList:
                  'flex flex-nowrap overflow-x-auto w-[calc(100%+4rem)] mx-[-2rem] sticky top-0 bg-white pt-4 pb-4 px-8 border-b border-white-600 bg-white hide-scroll z-10',
                tabLabel: 'font-medium',
                tab: 'data-[active=true]:bg-blue data-[active=true]:hover:bg-blue/90',
              }}
            >
              <Tabs.List>
                {residential.buildings &&
                  residential.buildings.map(building => (
                    <Tabs.Tab key={building.id} value={building.id.toString()}>
                      {getCurrentTranslation(building, language, 'name')}
                    </Tabs.Tab>
                  ))}
              </Tabs.List>
              {residential.buildings &&
                residential.buildings.map(building => (
                  <Tabs.Panel key={building.id} value={building.id.toString()} pt='sm'>
                    {isLoading && <UnitTableSkeleton />}
                    {!isLoading && currentBuilding && (
                      <>
                        {currentBuilding && (
                          <ResidentialBuildingCard
                            currentBuilding={currentBuilding}
                            onOpenEditModal={handleOpenEditBuildingModal}
                            onOpenUploadedPhotos={handleOpenUploadedBuildingPhotos}
                            onOpenDeleteModal={handleOpenDeleteBuildingModal}
                          />
                        )}
                        <UnitsInBuildingTable
                          onOpenEditModal={handleOpenEditModal}
                          onOpenCopyModal={handleOpenCopyModal}
                          onOpenDeleteModal={handleOpenDeleteModal}
                          onOpenPhotos={handleOpenUnitPhotos}
                          onOpenUploadedPhotos={handleOpenUploadedUnitPhotos}
                          onOpenFullDescription={handleOpenShowMoreModal}
                          currentBuilding={currentBuilding}
                          building_id={currentBuilding.id}
                          addButtonSlot={
                            <Button
                              variant='primary'
                              color='base'
                              size='md'
                              onClick={handleOpenCreateUnitModal}
                              leftIcon={<PlusCircleIcon size={16} />}
                            >
                              {t('unit:buttons.new_unit')}
                            </Button>
                          }
                        />
                      </>
                    )}
                  </Tabs.Panel>
                ))}
            </Tabs>
          </>
        ) : (
          <div className='text-sm text-left mt-4'>There are no buildings here yet</div>
        )}
        <CreateUnitModal
          dialogProps={{
            open: createDialogOpen,
            onOpenChange: value => handleOpenModal(value, 'createDialogOpen'),
          }}
          selectBuildingId={currentBuilding?.id}
          onCloseModal={handleCloseModal}
        />
        <CreateBuildingModal
          dialogProps={{
            open: createBuildingDialogOpen,
            onOpenChange: value => handleOpenModal(value, 'createBuildingDialogOpen'),
          }}
          selectResidentialId={residential.id}
          onCloseModal={handleCloseModal}
        />
        {currentBuilding && (
          <EditBuildingModal
            dialogProps={{
              open: editBuildingDialogOpen,
              onOpenChange: value => handleOpenModal(value, 'editBuildingDialogOpen'),
            }}
            onCloseModal={handleCloseModal}
            currentBuildingId={currentBuilding.id}
            selectResidentialId={residential.id}
          />
        )}
        <FullTextDescriptionModal
          dialogProps={{
            open: showMoreDialogOpen,
            onOpenChange: value => handleOpenModal(value, 'showMoreDialogOpen'),
          }}
          description={currentItem?.description}
        />
        {currentBuilding && (
          <DeleteBuildingAlert
            open={openDeleteBuildingDialog}
            onOpenChange={value => handleOpenModal(value, 'openDeleteBuildingDialog')}
            selectBuildingId={currentBuilding.id}
            onClose={handleCloseModal}
          />
        )}
        {selectPhotoId && openDeletePhotoDialog && (
          <DeletePhotoAlert
            open={openDeletePhotoDialog}
            onOpenChange={value => handleOpenModal(value, 'openDeletePhotoDialog')}
            selectPhotoId={selectPhotoId}
            queryKeyForUpdate={buildingsByPageQueryKey}
            onCloseModal={handleCloseCropModal}
          />
        )}
        {currentBuilding && selectPhotoId && (
          <EditPhotoCropModal
            dialogProps={{
              open: openEditCropPhotoDialog,
              onOpenChange: value => handleOpenModal(value, 'openEditCropPhotoDialog'),
            }}
            onCloseModal={() => {
              handleCloseCropModal()
              setLastImageCropTimestamp(new Date().getTime())
            }}
            selectedPhotoId={selectPhotoId}
            queryKeyForObjectUpdate={currentBuildingByIdQueryKey}
            queryKeyForPageUpdate={buildingsByPageQueryKey}
            obj_id={currentBuilding.id}
            owner={PhotoOwnerCaption.Building}
          />
        )}
        {currentBuilding && (
          <EditUploadedPhotosModal
            dialogProps={{
              open: openUploadedBuildingPhotoDialog,
              onOpenChange: value => handleOpenModal(value, 'openUploadedBuildingPhotoDialog'),
            }}
            obj_id={currentBuilding.id}
            owner={PhotoOwnerCaption.Building}
            lastImageCropTimestamp={lastImageCropTimestamp}
            uploadDropzoneSlot={
              <UploadPhotosForm
                obj_id={currentBuilding.id}
                ownerValue={ImagableType.Building}
                queryKeyForUpdate={currentBuildingByIdQueryKey}
              />
            }
            onDeletePhoto={handleDeletePhoto}
            onOpenCropPhoto={handleOpenEditCropPhoto}
          />
        )}
        {currentItem && (
          <EditUnitModal
            dialogProps={{
              open: editDialogOpen,
              onOpenChange: value => handleOpenModal(value, 'editDialogOpen'),
            }}
            currentUnitId={currentItem.id}
            selectBuildingId={currentBuilding?.id}
            onCloseModal={handleCloseModal}
          />
        )}
        {currentItem && (
          <CreateUnitModal
            dialogProps={{
              open: copyDialogOpen,
              onOpenChange: value => handleOpenModal(value, 'copyDialogOpen'),
            }}
            currentUnitId={currentItem.id}
            selectBuildingId={currentBuilding?.id}
            onCloseModal={handleCloseModal}
          />
        )}
        {currentItem && (
          <DeleteUnitAlert
            open={deleteDialogOpen}
            onOpenChange={value => handleOpenModal(value, 'deleteDialogOpen')}
            selectUnitId={currentItem.id}
            onCloseModal={handleCloseModal}
          />
        )}
        {currentItem && selectPhotoId && (
          <EditPhotoCropModal
            dialogProps={{
              open: openEditCropPhotoDialog,
              onOpenChange: value => handleOpenModal(value, 'openEditCropPhotoDialog'),
            }}
            onCloseModal={() => {
              handleCloseCropModal()
              setLastImageCropTimestamp(new Date().getTime())
            }}
            selectedPhotoId={selectPhotoId}
            queryKeyForObjectUpdate={currentUnitByIdQueryKey}
            queryKeyForPageUpdate={unitListsByBuildingIdQueryKey}
            obj_id={currentItem.id}
            owner={PhotoOwnerCaption.Unit}
          />
        )}
        {currentItem && (
          <EditUploadedPhotosModal
            dialogProps={{
              open: openUploadedPhotosDialog,
              onOpenChange: value => handleOpenModal(value, 'openUploadedPhotosDialog'),
            }}
            obj_id={currentItem.id}
            owner={PhotoOwnerCaption.Unit}
            lastImageCropTimestamp={lastImageCropTimestamp}
            uploadDropzoneSlot={
              <UploadPhotosForm
                obj_id={currentItem.id}
                ownerValue={ImagableType.Unit}
                queryKeyForUpdate={unitListsByBuildingIdQueryKey}
              />
            }
            onDeletePhoto={handleDeletePhoto}
            onOpenCropPhoto={handleOpenEditCropPhoto}
          />
        )}
        {showPhotosDialogOpen && currentItem && (
          <PhotosCarousel
            isOpened={showPhotosDialogOpen}
            obj_id={currentItem.id}
            owner={PhotoOwnerCaption.Unit}
            setIsOpened={handleOpenPhotos}
            onCloseModal={handleCloseModal}
            photos={currentItem?.photos || []}
          />
        )}
      </div>
    </>
  )
}

import { type FC } from 'react'
import { Form, Formik } from 'formik'
import { useCreateContactForm } from '../lib/use-create-contact-form'
import { CheckBoxField, PhoneField, SelectField, TextField } from '@/shared/ui/FormFields'
import { Button } from '@/shared/ui/Button/Button'
import { Loader } from '@mantine/core'
import { createContactFormSchema } from '../lib/consts'
import { useTranslatedEnums } from '@/shared/helpers/apiEnumTypesOptions'
import { useTranslation } from 'react-i18next'
import { InputAutocompleteField } from '@/shared/ui/Input/InputAutocompleteField'

type CreateContactFormProps = {
  onCloseModal: () => void
}

export const CreateContactForm: FC<CreateContactFormProps> = props => {
  const { onCloseModal } = props
  const { titleOptions, contactGenderOptions } = useTranslatedEnums()
  const {
    initialValues,
    userOptions,
    isLoadingCreateContact,
    submitForm,
    searchValue,
    setSearchValue,
    isLoadingUsers,
    createContactFormDefault,
  } = useCreateContactForm(onCloseModal)
  const { t } = useTranslation(['common', 'contact'])
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submitForm}
      validationSchema={createContactFormSchema(t)}
      enableReinitialize
    >
      <Form className='pt-4'>
        <div className='flex flex-row items-center space-x-4 mb-4 pt-4'>
          <CheckBoxField
            name='is_client'
            className='w-1/3'
            label={t('contact:client')}
            disabled={isLoadingCreateContact}
          />
        </div>
        <SelectField
          name='title'
          label={t('common:library_items.title.sing')}
          options={titleOptions}
          className='flex flex-col w-full mb-4'
          disabled={isLoadingCreateContact}
          required
        />
        <div className='flex flex-row w-full space-x-4'>
          <TextField
            name='name'
            label={t('contact:first_name')}
            className='flex flex-col w-full mb-4'
            required
          />
          <TextField
            name='second_name'
            label={t('contact:last_name')}
            className='flex flex-col w-full mb-4'
            required
          />
        </div>
        <TextField
          name='email'
          label={t('contact:email')}
          className='flex flex-col w-full mb-4'
          required
        />
        <div className='flex flex-row w-full space-x-4'>
          <PhoneField
            name='phone'
            label={t('contact:phone')}
            className='flex flex-col w-full mb-4'
            required
          />
          <PhoneField
            name='phone2'
            label={t('contact:second_phone')}
            className='flex flex-col w-full mb-4'
            defaultEmpty
            dropDownClassName={'!left-[-74px]'}
          />
        </div>
        <div className='flex flex-row w-full space-x-4'>
          <SelectField
            name='is_man'
            label={t('common:library_items.gender.sing')}
            options={contactGenderOptions}
            className='flex flex-col w-full mb-4'
          />
          <InputAutocompleteField
            name='user_id'
            label={t('contact:user')}
            onChange={setSearchValue}
            value={searchValue}
            className='flex flex-col w-full mb-4'
            data={userOptions}
            disabled={isLoadingCreateContact}
            isLoading={isLoadingUsers}
            initialValue={createContactFormDefault.user_id}
            required
          />
        </div>
        <div className='flex flex-row w-full'>
          <Button type='submit' size='md' color='base' disabled={isLoadingCreateContact}>
            {isLoadingCreateContact ? (
              <Loader size='xs' color='white' />
            ) : (
              t('contact:buttons.add_contact')
            )}
          </Button>
        </div>
      </Form>
    </Formik>
  )
}

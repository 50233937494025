import { Form, Formik } from 'formik'
import { FC, useMemo, type ReactNode } from 'react'
import { useEditUnitInlineForm } from '../lib/use-edit-unit-inline-form'
import { Loader, ActionIcon as MantineActionButton } from '@mantine/core'
import { PenSquare, Save, X } from 'lucide-react'
import { type ShowedInlineForm } from '../model/types'
import { Building } from '@/shared/api/topli/TopliApi'

type EditUnitInlineFormProps = {
  nameField: string
  selectedUnitId: number
  fieldSlot?: ReactNode
  isShowedForm: ShowedInlineForm | null
  setIsShowedForm: (form: ShowedInlineForm) => void
  currentBuilding?: Building
}

export const EditUnitInlineForm: FC<EditUnitInlineFormProps> = props => {
  const { nameField, isShowedForm, selectedUnitId, fieldSlot, setIsShowedForm, currentBuilding } =
    props
  const { initialValues, isLoadingEditUnit, handleHideForm, handleShowForm, submitForm } =
    useEditUnitInlineForm(selectedUnitId, nameField, setIsShowedForm, currentBuilding)
  const isVisibleForm = useMemo(
    () =>
      !isShowedForm ||
      isShowedForm?.unitId !== selectedUnitId ||
      (isShowedForm && !isShowedForm[nameField]),
    [isShowedForm, nameField, selectedUnitId],
  )

  if (isVisibleForm) {
    return (
      <MantineActionButton
        className='text-gray-300 bg-transparent hover:bg-blue hover:text-white rounded-full transition-all'
        variant='light'
        onClick={handleShowForm}
      >
        <PenSquare size={16} />
      </MantineActionButton>
    )
  }
  return (
    <Formik initialValues={initialValues} onSubmit={submitForm} enableReinitialize>
      <Form>
        <div className='flex flex-row w-full gap-x-2 items-center'>
          {fieldSlot}
          <div className='flex flex-row gap-x-2'>
            <MantineActionButton
              type='button'
              className='text-red bg-red/10 hover:bg-blue hover:text-white rounded-full transition-all'
              variant='light'
              onClick={handleHideForm}
            >
              <X size={16} />
            </MantineActionButton>
            <MantineActionButton
              type='submit'
              className='text-blue bg-blue/10 hover:bg-blue hover:text-white rounded-full transition-all'
              variant='light'
            >
              {isLoadingEditUnit ? <Loader size='xs' color='white' /> : <Save size={16} />}
            </MantineActionButton>
          </div>
        </div>
      </Form>
    </Formik>
  )
}

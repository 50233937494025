import { FC } from 'react'
import { Form, Formik } from 'formik'
import { useCreateUnitForm } from '../lib/use-create-unit-form'
import { createUnitFormScheme, createUnitFormTabsConfig } from '../lib/consts'
import { Text } from '@mantine/core'
import {
  CheckBoxField,
  MultiSelectField,
  NumberField,
  SelectField,
  TextField,
} from '@/shared/ui/FormFields'
import { Button } from '@/shared/ui/Button/Button'
import { Loader } from '@mantine/core'
import { createPriceFormFieldDefault } from '@/features/Price/CreatePriceFormField/lib/consts'
import { CreatePriceFieldArray } from '@/features/Price/CreatePriceFormField'
import { useTranslatedEnums } from '@/shared/helpers/apiEnumTypesOptions'
import { useTranslation } from 'react-i18next'
import LanguageTabs from '@/shared/ui/LanguageTabs/LanguageTabs'
import { InputAutocompleteField } from '@/shared/ui/Input/InputAutocompleteField'

type CreateUnitFormProps = {
  selectBuildingId?: number
  currentUnitId?: number
  onCloseModal: () => void
}

export const CreateUnitForm: FC<CreateUnitFormProps> = ({
  selectBuildingId,
  currentUnitId,
  onCloseModal,
}) => {
  const {
    initialValues,
    buildingsOptions,
    amenitiesOptions,
    isLoadingCreateUnit,
    isLoadingBuildings,
    isLoadingAmenities,
    searchBuilding,
    setSearchBuilding,
    submitForm,
    createUnitFormDefault,
  } = useCreateUnitForm(onCloseModal, currentUnitId, selectBuildingId)

  const { conditionOptions, accommodationTypeOptions } = useTranslatedEnums()
  const { t } = useTranslation(['unit', 'common'])

  const languageTabs = createUnitFormTabsConfig(isLoadingCreateUnit)

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submitForm}
      validationSchema={createUnitFormScheme(t)}
      enableReinitialize
    >
      <Form>
        <div className=' w-100 mx-[-2rem] pb-4 px-8 border-b border-white-600 bg-neutral-100'>
          <LanguageTabs config={languageTabs} />
        </div>
        <div className='flex flex-row items-center space-x-4 mb-4 pt-4'>
          <CheckBoxField
            name='activity'
            className='w-1/3'
            label={t('unit:activity')}
            disabled={isLoadingCreateUnit}
          />
          <CheckBoxField
            name='hide_price'
            className='w-1/3'
            label={t('unit:hide_price')}
            disabled={isLoadingCreateUnit}
          />
        </div>
        <div className='flex flex-row items-center space-x-4 mb-4 pt-4'>
          <InputAutocompleteField
            name='building_id'
            label={t('unit:building')}
            onChange={setSearchBuilding}
            value={searchBuilding}
            className='flex flex-col w-full mb-4'
            data={buildingsOptions}
            disabled={isLoadingCreateUnit || !!selectBuildingId}
            isLoading={isLoadingBuildings}
            initialValue={createUnitFormDefault.building_id}
            required
          />
          <SelectField
            name='type'
            label={t('common:library_items.accommodation_type.sing')}
            options={accommodationTypeOptions}
            className='flex flex-col w-full mb-4'
            disabled={isLoadingCreateUnit}
            required
          />
        </div>
        <div className='flex flex-row items-center space-x-4'>
          <NumberField
            name='size'
            label={t('unit:gross_size')}
            className='flex flex-col w-full mb-4'
            disabled={isLoadingCreateUnit}
            required
            precision={2}
            step={0.01}
            min={0}
          />
          <NumberField
            name='net_size'
            label={t('unit:net_size')}
            className='flex flex-col w-full mb-4'
            disabled={isLoadingCreateUnit}
            required
            precision={2}
            step={0.01}
            min={0}
          />
          <NumberField
            name='lotSize'
            label={t('unit:lot_size')}
            className='flex flex-col w-full mb-4'
            disabled={isLoadingCreateUnit}
            precision={2}
            step={0.01}
            min={0}
          />
        </div>
        <div className='flex flex-row items-center space-x-4'>
          <NumberField
            name='bedrooms'
            label={t('unit:bedrooms')}
            className='flex flex-col w-full mb-4'
            disabled={isLoadingCreateUnit}
            required
            min={0}
          />
          <NumberField
            name='bathrooms'
            label={t('unit:bathrooms')}
            className='flex flex-col w-full mb-4'
            disabled={isLoadingCreateUnit}
            required
            min={0}
          />
        </div>
        <div className='flex flex-row items-center space-x-4'>
          <TextField
            name='level'
            label={t('unit:level')}
            type='number'
            className='flex flex-col min-w-[calc(50%-0.5rem)] mb-4'
            disabled={isLoadingCreateUnit}
          />
          <SelectField
            name='condition'
            label={t('common:library_items.condition.sing')}
            options={conditionOptions}
            className='flex flex-col w-full mb-4'
            disabled={isLoadingCreateUnit}
          />
        </div>
        <div className='flex flex-row items-center space-x-4'>
          <TextField
            name='video_url'
            label={t('unit:video_url')}
            className='flex flex-col w-full mb-4'
            disabled={isLoadingCreateUnit}
          />
        </div>
        <MultiSelectField
          name='amenities'
          label={t('unit:amenities')}
          options={amenitiesOptions}
          className='flex flex-col w-full mb-4'
          disabled={isLoadingAmenities || isLoadingCreateUnit}
        />
        <div className='flex flex-col w-full mb-4'>
          <Text component='div' className='text-md font-semibold text-left mb-4'>
            {t('unit:price')}
          </Text>
          <NumberField
            name='fee'
            label={t('unit:service_fee')}
            className='flex flex-col w-full mb-4'
            precision={2}
            step={0.01}
            min={0}
          />
          <CreatePriceFieldArray namePrice='prices' defaultValue={createPriceFormFieldDefault} />
        </div>
        <div className='flex flex-row w-full'>
          <Button type='submit' size='md' color='base' disabled={isLoadingCreateUnit}>
            {isLoadingCreateUnit ? <Loader size='xs' color='white' /> : t('unit:buttons.add_unit')}
          </Button>
        </div>
      </Form>
    </Formik>
  )
}

import * as Yup from 'yup'
import { UserRole, UserStatus } from '@/shared/api/topli/TopliApi'
import { EditUserFormState } from '../model/types'
import { validatePhoneNumber } from '@/shared/helpers/validatePhoneNumber'
import { TFunction } from 'i18next'

export const editUserFormDefault: EditUserFormState = {
  id: 0,
  status: UserStatus.Active,
  fullname: '',
  email: '',
  phone: '',
  role: UserRole.Admin,
  is_manager: false,
  is_content_manager: false,
}

export const editUserFormScheme = (t: TFunction) => {
  return Yup.object().shape({
    fullname: Yup.string()
      .required(t('common:validation_messages.required'))
      .max(127, t('common:validation_messages.max', { count: 127 })),
    email: Yup.string()
      .email(t('common:validation_messages.invalid_email'))
      .required(t('common:validation_messages.required'))
      .max(63, t('common:validation_messages.max', { count: 63 })),
    phone: Yup.string()
      .required(t('common:validation_messages.required'))
      .test('phone', t('common:validation_messages.invalid_phone'), value =>
        validatePhoneNumber(value),
      ),
    is_manager: Yup.boolean(),
    is_content_manager: Yup.boolean(),
    status: Yup.mixed<UserStatus>().oneOf(Object.values(UserStatus) as number[]),
    role: Yup.mixed<UserRole>().oneOf(Object.values(UserRole) as number[]),
  })
}

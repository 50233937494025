import { useEffect, useState } from 'react'
import { EditBuildingFormState } from '../model/types'
import { editBuildingFormDefault } from './consts'
import { FormikConfig } from 'formik'
import { notification } from '@/shared/helpers/notificationMessages'
import { errorHandler } from '@/shared/lib/errorHandler'
import { useEditBuildingApi } from '../api/edit-building-form-api'
import { useBuildingById } from '@/entities/Building'
import { useTranslation } from 'react-i18next'
import {
  createAutocompleteOptions,
  getCurrentTranslation,
  hasAiTranslation,
  matchTranslations,
} from '@/shared/lib/utils'
import { useLanguageStore } from '@/widgets/LanguageSelect/model/store'
import { EditTranslations } from '@/shared/types/ContentTranslations'
import { updateTranslationsForEditing } from '@/shared/lib/updateTranslationsForEditing'
import { useDebounce } from '@/shared/lib/useDebounce'
import { useSearchResidentialsByText } from '@/entities/Residential/api/residential-api'

export const useEditBuildingForm = (currentBuildingId: number, onCloseModal: () => void) => {
  const [searchValue, setSearchValue] = useState<string>('')
  const [originalTranslations, setOriginalTranslations] = useState<EditTranslations | null>(null)
  const [initialValues, setInitialValues] = useState<EditBuildingFormState>(
    () => editBuildingFormDefault,
  )
  const { language } = useLanguageStore()
  const { t } = useTranslation(['common'])
  const debouncedSearchValue = useDebounce(searchValue, 500)

  const { data: residentials, isLoading: isLoadingResidentials } = useSearchResidentialsByText(
    language,
    debouncedSearchValue,
  )

  const {
    data: currentBuilding,
    isLoading: isLoadingBuilding,
    isError: isErrorBuilding,
  } = useBuildingById(currentBuildingId)

  const {
    mutateAsync,
    isPending: isLoadingEditBuilding,
    isSuccess: isSuccessEditBuilding,
  } = useEditBuildingApi()

  const submitForm: FormikConfig<EditBuildingFormState>['onSubmit'] = async values => {
    const { address_id, translations, ...restValues } = values
    const updatedTranslations = updateTranslationsForEditing(translations, originalTranslations)
    const hasAiTranslationFlag = hasAiTranslation(updatedTranslations)

    const updatedValues = {
      ...restValues,
      has_ai_translation: hasAiTranslationFlag,
      translations: updatedTranslations,
    }

    try {
      await mutateAsync(updatedValues)
      onCloseModal()
    } catch (error) {
      errorHandler(error, t('common:notification_messages.edit_building_error'))
    }
  }

  useEffect(() => {
    if (currentBuilding) {
      setInitialValues(() => {
        const {
          delivery_date,
          activity,
          done,
          hide_price,
          car_unitcount,
          unitcount,
          area,
          id,
          parking_type,
          polygon,
          nol,
          type,
          residential,
          has_ai_translation,
          translations,
        } = currentBuilding
        setSearchValue(getCurrentTranslation(residential, language, 'name'))
        const matchedTranslations = matchTranslations(translations, initialValues.translations)
        setOriginalTranslations({ ...matchedTranslations })
        return {
          delivery_date: delivery_date ? new Date(delivery_date as string) : null,
          activity: activity ?? false,
          done,
          nol: nol ?? 0,
          hide_price,
          car_unitcount: car_unitcount ?? 0,
          unitcount: unitcount ?? 0,
          area,
          translations: matchedTranslations,
          id,
          parking_type,
          has_ai_translation,
          polygon,
          type,
          residential_id: residential.id,
          address_id: residential.address.id,
        }
      })
    }
  }, [currentBuilding])

  const residentialsOptions = createAutocompleteOptions(residentials?.data, language)

  useEffect(() => {
    if (isSuccessEditBuilding) {
      notification.success(
        t('common:notification_titles.success'),
        t('common:notification_messages.edit_building_success'),
      )
    }
  }, [isSuccessEditBuilding])

  return {
    initialValues,
    isLoadingEditBuilding,
    isLoadingResidentials,
    isLoadingBuilding,
    isErrorBuilding,
    submitForm,
    searchValue,
    setSearchValue,
    residentialsOptions,
    editBuildingFormDefault,
    residentials: residentials?.data,
  }
}

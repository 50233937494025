import { useQuery } from '@tanstack/react-query'
import { topliApi } from '@/shared/api/topli/TopliApi'
import { CompaniesRequestParams } from './model/types'

export const companiesQueryKey = 'companies'
export const currentCompanyByIdQueryKey = 'currentCompanyById'
export const searchCompanyQueryKey = 'searchCompanies'

export const fetchCompanies = async (language: string) => {
  const response = await topliApi.company.list({ language })
  return response
}

export const fetchCompaniesByPage = async (
  sort: string | null,
  limit: number,
  offset: number,
  search: string,
  language: string,
) => {
  const requestParams: CompaniesRequestParams = {
    limit,
    offset,
    sort,
    language,
  }

  if (search.length) {
    requestParams.translations__name__icontains = search
    requestParams.translations__language__icontains = language
  }

  const response = await topliApi.company.list(requestParams)
  return response
}

export const searchCompanyByName = async (language: string, search?: string) => {
  if (!search) return
  const response = await topliApi.company.list({
    translations__name__icontains: search,
    translations__language__icontains: language,
    language: language,
  })
  return response
}

export const fetchCompanyById = async (id?: number) => {
  if (!id) return
  const response = await topliApi.company.get(id)
  return response
}

export const useCompanyById = (id?: number) =>
  useQuery({
    queryKey: [currentCompanyByIdQueryKey, id],
    queryFn: () => fetchCompanyById(id),
    enabled: !!id,
  })

export const useCompanies = (language: string) =>
  useQuery({
    queryKey: [companiesQueryKey, language],
    queryFn: () => fetchCompanies(language),
  })

export const useSearchCompaniesByName = (language: string, search?: string) => {
  const data = useQuery({
    queryKey: [searchCompanyQueryKey, language, search],
    queryFn: () => searchCompanyByName(language, search),
    enabled: !!search,
  })
  return data
}

export const useCompaniesByPage = (
  sortings: string | null,
  limit: number,
  page: number,
  search: string,
  language: string,
) =>
  useQuery({
    queryKey: [companiesQueryKey, sortings, page, limit, search || '', language],
    queryFn: () => fetchCompaniesByPage(sortings, limit, page, search || '', language),
  })

import { useQuery } from '@tanstack/react-query'
import { topliApi } from '@/shared/api/topli/TopliApi'

export const contactListsQueryKey = 'contactLists'
export const currentContactByIdQueryKey = 'currentContactById'
export const contactListsByPageQueryKey = 'contactListsByPage'
export const searchContactsQueryKey = 'searchContacts'

export const fetchContactsList = async () => {
  const response = await topliApi.contact.list({})
  return response
}

export const fetchContactsListByPage = async (
  sort: string | null,
  limit: number,
  offset: number,
  search: string,
) => {
  const response = await topliApi.contact.list({ limit, offset, sort, fullname__icontains: search })
  return response
}

export const searchContactsByName = async (search?: string) => {
  if (!search) return
  const response = await topliApi.contact.list({
    fullname__icontains: search,
  })
  return response
}

export const fetchContactById = async (id?: number) => {
  if (!id) return
  const response = await topliApi.contact.get(id)
  return response
}

export const useSearchContactsByName = (search?: string) => {
  const data = useQuery({
    queryKey: [searchContactsQueryKey, search],
    queryFn: () => searchContactsByName(search),
    enabled: !!search,
  })

  return data
}

export const useContactById = (id?: number) =>
  useQuery({
    queryKey: [currentContactByIdQueryKey, id],
    queryFn: () => fetchContactById(id),
    enabled: !!id,
  })

export const useContacts = () =>
  useQuery({
    queryKey: [contactListsQueryKey],
    queryFn: fetchContactsList,
  })

export const useContactsByPage = (
  sorting: string | null,
  limit: number,
  page: number,
  search: string,
) =>
  useQuery({
    queryKey: [contactListsByPageQueryKey, sorting, page, limit, search || ''],
    queryFn: () => fetchContactsListByPage(sorting, limit, page, search || ''),
  })

import { subscribeOnPolygonEdit } from '@/shared/ui/Map/hooks/useDrawingPolygon/utils'
import { useGoogleMapApiLoader } from '@/shared/ui/Map/hooks/useGoogleMapApiLoader'
import { useCallback, useEffect, useRef } from 'react'
export const useDrawingPolygon = (
  map: google.maps.Map | null,
  onPolygonCreate: (polygon: google.maps.Polygon) => void,
  onPolygonEdit: (polygon: google.maps.Polygon) => void,
  onClearPolygon: () => void,
) => {
  const loader = useGoogleMapApiLoader()
  const drawingRef = useRef<google.maps.drawing.DrawingManager | null>(null)
  const polygonRef = useRef<google.maps.Polygon | null>(null)

  useEffect(() => {
    const removesEventsListeners: Array<google.maps.MapsEventListener> = []
    const getAndSetDrawingManager = async () => {
      const { DrawingManager } = await loader.importLibrary('drawing')
      const drawingManager = new DrawingManager({
        drawingControl: true,
        drawingMode: google.maps.drawing.OverlayType.POLYGON,
        drawingControlOptions: {
          position: google.maps.ControlPosition.TOP_RIGHT,
          drawingModes: [google.maps.drawing.OverlayType.POLYGON],
        },
        polygonOptions: {
          editable: true,
        },
        map,
      })
      const removeOverlayComplete = drawingManager.addListener(
        'overlaycomplete',
        (e: google.maps.drawing.OverlayCompleteEvent) => {
          drawingManager.setDrawingMode(null)
          if (e.type === google.maps.drawing.OverlayType.POLYGON && e.overlay !== null) {
            const overlay = e.overlay as google.maps.Polygon
            polygonRef.current = overlay
            onPolygonCreate(overlay)
            const removePolygonsEventListenersFuncs = subscribeOnPolygonEdit(overlay, onPolygonEdit)
            removesEventsListeners.concat(removePolygonsEventListenersFuncs)
          }
        },
      )
      removesEventsListeners.push(removeOverlayComplete)
      drawingRef.current = drawingManager
    }
    getAndSetDrawingManager()

    return () => removesEventsListeners.forEach(l => l.remove())
  }, [loader, map, onPolygonCreate, onPolygonEdit])

  const addPolygon = useCallback(
    async (paths: google.maps.LatLngLiteral[]) => {
      const { Polygon } = await loader.importLibrary('maps')
      if (polygonRef.current) {
        polygonRef.current.setMap(null)
      }
      const polygon = new Polygon({ paths, map })
      drawingRef.current?.bindTo('a', polygon)
      polygonRef.current = polygon
      return polygon
    },
    [loader, map],
  )

  const clearPolygon = useCallback(() => {
    if (polygonRef.current) {
      onClearPolygon()
      polygonRef.current.setMap(null)
      polygonRef.current = null
    }
  }, [onClearPolygon])

  return { addPolygon, subscribeOnPolygonEdit, clearPolygon }
}

import { type FC } from 'react'
import { Form, Formik } from 'formik'
import { useEditContactForm } from '../lib/use-edit-contact-form'
import { CheckBoxField, PhoneField, SelectField, TextField } from '@/shared/ui/FormFields'
import { Button } from '@/shared/ui/Button/Button'
import { Loader } from '@mantine/core'
import { editContactFormSchema } from '../lib/consts'
import { useTranslatedEnums } from '@/shared/helpers/apiEnumTypesOptions'
import { useTranslation } from 'react-i18next'
import { InputAutocompleteField } from '@/shared/ui/Input/InputAutocompleteField'

type EditContactFormProps = {
  currentContactId: number
  onCloseModal: () => void
}

export const EditContactForm: FC<EditContactFormProps> = props => {
  const { currentContactId, onCloseModal } = props
  const { titleOptions, contactGenderOptions } = useTranslatedEnums()
  const {
    initialValues,
    userOptions,
    isLoadingEditContact,
    submitForm,
    searchValue,
    setSearchValue,
    isLoadingUsers,
    editContactFormDefault,
  } = useEditContactForm(currentContactId, onCloseModal)
  const { t } = useTranslation(['common', 'contact'])

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submitForm}
      enableReinitialize
      validationSchema={editContactFormSchema(t)}
    >
      <Form className='pt-4'>
        <div className='flex flex-row items-center space-x-4 mb-4 pt-4'>
          <CheckBoxField
            name='is_client'
            className='w-1/3'
            label={t('contact:client')}
            disabled={isLoadingEditContact}
          />
        </div>
        <SelectField
          name='title'
          label={t('common:library_items.title.sing')}
          options={titleOptions}
          className='flex flex-col w-full mb-4'
          disabled={isLoadingEditContact}
          required
        />
        <div className='flex flex-row w-full space-x-4'>
          <TextField
            name='name'
            label={t('contact:first_name')}
            className='flex flex-col w-full mb-4'
            required
            disabled={isLoadingEditContact}
          />
          <TextField
            name='second_name'
            label={t('contact:last_name')}
            className='flex flex-col w-full mb-4'
            required
            disabled={isLoadingEditContact}
          />
        </div>
        <TextField name='email' label={t('contact:email')} className='flex flex-col w-full mb-4' />
        <div className='flex flex-row w-full space-x-4'>
          <PhoneField
            name='phone'
            label={t('contact:phone')}
            className='flex flex-col w-full mb-4'
            required
            disabled={isLoadingEditContact}
          />
          <PhoneField
            name='phone2'
            label={t('contact:second_phone')}
            className='flex flex-col w-full mb-4'
            disabled={isLoadingEditContact}
            defaultEmpty
            dropDownClassName={'!left-[-74px]'}
          />
        </div>
        <div className='flex flex-row w-full space-x-4'>
          <SelectField
            name='is_man'
            label={t('common:library_items.gender.sing')}
            options={contactGenderOptions}
            className='flex flex-col w-full mb-4'
            disabled={isLoadingEditContact}
          />
          <InputAutocompleteField
            name='user_id'
            label={t('contact:user')}
            onChange={setSearchValue}
            value={searchValue}
            className='flex flex-col w-full mb-4'
            data={userOptions}
            disabled={isLoadingEditContact}
            isLoading={isLoadingUsers}
            initialValue={editContactFormDefault.user_id}
            required
          />
        </div>
        <div className='flex flex-row w-full'>
          <Button type='submit' size='md' color='base' disabled={isLoadingEditContact}>
            {isLoadingEditContact ? (
              <Loader size='xs' color='white' />
            ) : (
              t('contact:buttons.save_changes')
            )}
          </Button>
        </div>
      </Form>
    </Formik>
  )
}

import { type FC } from 'react'
import { Form, Formik } from 'formik'
import { useEditClientForm } from '../lib/use-edit-client-form'
import { SelectField, TextAreaField } from '@/shared/ui/FormFields'
import { Button } from '@/shared/ui/Button/Button'
import { Loader } from '@mantine/core'
import { editClientFormSchema } from '../lib/consts'
import { useTranslatedEnums } from '@/shared/helpers/apiEnumTypesOptions'
import { useTranslation } from 'react-i18next'
import { InputAutocompleteField } from '@/shared/ui/Input/InputAutocompleteField'

type EditClientFormProps = {
  currentClientId: number
  onCloseModal: () => void
}

export const EditClientForm: FC<EditClientFormProps> = props => {
  const { currentClientId, onCloseModal } = props
  const { orderStatusOptions } = useTranslatedEnums()
  const {
    initialValues,
    userOptions,
    contactOptions,
    residentialsOptions,
    isLoadingUsers,
    isLoadingEditClient,
    submitForm,
    isLoadingContacts,
    searchUser,
    setSearchUser,
    searchContact,
    setSearchContact,
    editClientFormDefault,
    isLoadingResidentials,
    searchResidential,
    setSearchResidential,
  } = useEditClientForm(currentClientId, onCloseModal)

  const { t } = useTranslation(['common', 'client'])

  if (isLoadingEditClient) {
    return (
      <div className='flex flex-col py-8 items-center justify-center'>
        <Loader size='xs' color='blue' />
      </div>
    )
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submitForm}
      enableReinitialize
      validationSchema={editClientFormSchema(t)}
    >
      <Form className='pt-4'>
        <div className='flex flex-row w-full space-x-4'>
          <InputAutocompleteField
            name='created_by_id'
            label={t('client:agent')}
            onChange={setSearchUser}
            value={searchUser}
            className='flex flex-col w-full mb-4'
            data={userOptions}
            disabled={isLoadingEditClient}
            isLoading={isLoadingUsers}
            initialValue={editClientFormDefault.created_by_id}
            required
          />
          <InputAutocompleteField
            name='contact_id'
            label={t('client:client')}
            onChange={setSearchContact}
            value={searchContact}
            className='flex flex-col w-full mb-4'
            data={contactOptions}
            disabled={isLoadingEditClient}
            isLoading={isLoadingContacts}
            initialValue={editClientFormDefault.contact_id}
            required
          />
        </div>
        <div className='flex flex-row w-full space-x-4'>
          <SelectField
            name='status'
            label={t('client:order_status')}
            options={orderStatusOptions}
            className='flex flex-col w-full mb-4'
            disabled={isLoadingEditClient}
          />
        </div>
        <InputAutocompleteField
          name='residential_id'
          label={t('client:residential')}
          onChange={setSearchResidential}
          value={searchResidential}
          className='flex flex-col w-full mb-4'
          data={residentialsOptions}
          disabled={isLoadingEditClient}
          isLoading={isLoadingResidentials}
          initialValue={editClientFormDefault.residential_id}
          required
        />
        <TextAreaField
          name='comment'
          label={t('client:comment')}
          className='flex flex-col w-full mb-4'
          disabled={isLoadingEditClient}
        />
        <div className='flex flex-row w-full'>
          <Button type='submit' size='md' color='base' disabled={isLoadingEditClient}>
            {isLoadingEditClient ? (
              <Loader size='xs' color='white' />
            ) : (
              t('client:buttons.save_changes')
            )}
          </Button>
        </div>
      </Form>
    </Formik>
  )
}

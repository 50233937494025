import { Building, UnitListItem } from '@/shared/api/topli/TopliApi'
import { useUnitsInBuildingTable } from '../lib/use-units-in-building-table'
import { FC, ReactNode } from 'react'
import {
  MRT_GlobalFilterTextInput,
  MRT_ShowHideColumnsButton,
  MantineReactTable,
  useMantineReactTable,
} from 'mantine-react-table'
import { ActionIcon as MantineActionButton, Menu, Switch } from '@mantine/core'
import { ImageIcon, PenIcon, Trash, Copy } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { getLocalization } from '@/widgets/LanguageSelect/lib/consts'

type UnitsInBuildingTableProps = {
  building_id: number
  currentBuilding: Building
  addButtonSlot: ReactNode
  onOpenEditModal: (row: UnitListItem) => void
  onOpenCopyModal: (row: UnitListItem) => void
  onOpenDeleteModal: (row: UnitListItem) => void
  onOpenPhotos: (row: UnitListItem) => void
  onOpenUploadedPhotos: (row: UnitListItem) => void
  onOpenFullDescription: (row: UnitListItem) => void
}

export const UnitsInBuildingTable: FC<UnitsInBuildingTableProps> = props => {
  const {
    building_id,
    addButtonSlot,
    onOpenEditModal,
    onOpenCopyModal,
    onOpenDeleteModal,
    onOpenFullDescription,
    onOpenPhotos,
    onOpenUploadedPhotos,
    currentBuilding,
  } = props

  const { t, i18n } = useTranslation(['unit'])
  const localization = getLocalization(i18n.language)

  const {
    pagination,
    fetchedUnits,
    totalRowCount,
    columns,
    sorting,
    isErrorUnits,
    isLoadingUnits,
    isFetchingUnits,
    isShowLocalPrices,
    setSorting,
    setPagination,
    setIsShowLocalPrices,
    handleSearchChange,
  } = useUnitsInBuildingTable(onOpenFullDescription, onOpenPhotos, building_id, currentBuilding)
  const table = useMantineReactTable({
    columns,
    data: fetchedUnits,
    enableStickyHeader: false,
    enableColumnActions: false,
    enableEditing: false,
    manualPagination: true,
    manualSorting: true,
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    rowCount: totalRowCount,
    localization,
    enableRowActions: true,
    onGlobalFilterChange: handleSearchChange,
    renderRowActions: ({ row }) => (
      <div className='flex flex-row space-x-1 pr-8'>
        <MantineActionButton
          onClick={() => onOpenEditModal(row.original)}
          className='text-blue bg-blue/10 hover:bg-blue hover:text-white rounded-full transition-all'
          variant='light'
        >
          <PenIcon size={16} />
        </MantineActionButton>
        <MantineActionButton
          onClick={() => onOpenCopyModal(row.original)}
          className='text-blue bg-blue/10 hover:bg-blue hover:text-white rounded-full transition-all'
          variant='light'
        >
          <Copy size={16} />
        </MantineActionButton>
        <MantineActionButton
          onClick={() => onOpenUploadedPhotos(row.original)}
          className='text-blue bg-blue/10 hover:bg-blue hover:text-white rounded-full transition-all'
          variant='light'
        >
          <ImageIcon size={16} />
        </MantineActionButton>
        <MantineActionButton
          onClick={() => onOpenDeleteModal(row.original)}
          className='text-red bg-red/10 hover:bg-red hover:text-white rounded-full transition-all'
          variant='light'
        >
          <Trash size={16} />
        </MantineActionButton>
      </div>
    ),
    renderTopToolbar: ({ table }) => {
      return (
        <div className='flex flex-row items-center justify-between mb-2'>
          <MRT_GlobalFilterTextInput table={table} />
          <div className='flex flex-row items-center space-x-2'>
            <Switch
              label={t('unit:show_is_local_price')}
              checked={isShowLocalPrices}
              onChange={event => setIsShowLocalPrices(event.currentTarget.checked)}
            />
            <MRT_ShowHideColumnsButton className='h-[2.5rem] w-[2.5rem] rounded-md' table={table} />
            {addButtonSlot}
          </div>
        </div>
      )
    },
    mantinePaperProps: {
      shadow: 'none',
      withBorder: false,
    },
    mantineTableProps: {
      sx: {
        tableLayout: 'auto',
      },
    },
    mantineTableBodyProps: {
      sx: {
        '&: td': {
          minWidth: 90,
        },
        '& tr:hover': {
          backgroundColor: '#FFFFFF',
        },
      },
    },
    mantineTableBodyRowProps: {
      className: 'hover:bg-[#F4FAFD]/10',
    },
    state: {
      pagination,
      sorting,
      isLoading: isLoadingUnits,
      showGlobalFilter: true,
      showAlertBanner: isErrorUnits,
      showProgressBars: isFetchingUnits,
    },
  })

  return <MantineReactTable table={table} />
}

import { useEffect, useState } from 'react'
import { type EditClientFormState } from '../model/types'
import { editClientFormDefault } from './consts'
import { FormikConfig } from 'formik'
import { errorHandler } from '@/shared/lib/errorHandler'
import { useEditClientApi } from '../api/edit-client-form-api'
import { notification } from '@/shared/helpers/notificationMessages'
import { createAutocompleteOptions, getCurrentTranslation } from '@/shared/lib/utils'
import { useTranslation } from 'react-i18next'
import { useLanguageStore } from '@/widgets/LanguageSelect/model/store'
import { useClientById } from '@/entities/Clients'
import { useDebounce } from '@/shared/lib/useDebounce'
import { useSearchUsersByName } from '@/entities/User/api/user-api'
import { useSearchContactsByName } from '@/entities/Contact/api/contact-api'
import { useSearchResidentialsByText } from '@/entities/Residential/api/residential-api'

export const useEditClientForm = (currentClientId: number, onCloseModal: () => void) => {
  const [initialValues, setInitialValues] = useState<EditClientFormState>(
    () => editClientFormDefault,
  )
  const [searchUser, setSearchUser] = useState<string>('')
  const [searchContact, setSearchContact] = useState<string>('')
  const [searchResidential, setSearchResidential] = useState<string>('')
  const { t } = useTranslation(['client'])
  const { language } = useLanguageStore()
  const debouncedSearchUser = useDebounce(searchUser, 500)
  const debouncedSearchContact = useDebounce(searchContact, 500)
  const debouncedSearchResidential = useDebounce(searchResidential, 500)
  const { data: users, isLoading: isLoadingUsers } = useSearchUsersByName(debouncedSearchUser)

  const { data: contacts, isLoading: isLoadingContacts } =
    useSearchContactsByName(debouncedSearchContact)

  const { data: residentials, isLoading: isLoadingResidentials } = useSearchResidentialsByText(
    language,
    debouncedSearchResidential,
  )
  const {
    data: currentClientOrder,
    isLoading: isLoadingClient,
    isError: isErrorClient,
  } = useClientById(currentClientId)

  const {
    mutateAsync,
    isPending: isLoadingEditClient,
    isSuccess: isSuccessEditClient,
  } = useEditClientApi()

  const submitForm: FormikConfig<EditClientFormState>['onSubmit'] = async values => {
    try {
      await mutateAsync(values)
      onCloseModal()
    } catch (error) {
      errorHandler(error, t('common:notification_messages.edit_client_error'))
    }
  }

  useEffect(() => {
    if (currentClientOrder) {
      const { id, contact, residential, created_by, comment, status } = currentClientOrder
      setSearchUser(created_by?.fullname ?? '')
      setSearchContact(contact?.fullname ?? '')
      setSearchResidential(getCurrentTranslation(residential, language, 'name'))
      setInitialValues(() => {
        return {
          id,
          contact_id: contact.id,
          residential_id: residential.id,
          created_by_id: created_by.id,
          comment,
          status,
        }
      })
    }
  }, [currentClientOrder])

  useEffect(() => {
    if (isSuccessEditClient) {
      notification.success(
        t('common:notification_titles.success'),
        t('common:notification_messages.edit_client_success'),
      )
    }
  }, [isSuccessEditClient])

  const userOptions = (users?.data || []).map(user => ({
    value: String(user.id),
    label: user.fullname as string,
  }))

  const contactOptions = (contacts?.data || []).map(contact => ({
    value: String(contact.id),
    label: contact.fullname as string,
  }))

  const residentialsOptions = createAutocompleteOptions(residentials?.data, language)

  return {
    initialValues,
    userOptions,
    isLoadingUsers,
    isLoadingEditClient,
    isLoadingClient,
    isErrorClient,
    submitForm,
    contactOptions,
    residentialsOptions,
    isLoadingContacts,
    isLoadingResidentials,
    searchUser,
    searchContact,
    setSearchUser,
    setSearchContact,
    editClientFormDefault,
    searchResidential,
    setSearchResidential,
  }
}

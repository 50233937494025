import { useEffect, useState } from 'react'
import { type CreateClientFormState } from '../model/types'
import { createClientFormDefault } from './consts'
import { FormikConfig } from 'formik'
import { errorHandler } from '@/shared/lib/errorHandler'
import { useCreateClientApi } from '../api/create-contact-form-api'
import { notification } from '@/shared/helpers/notificationMessages'
import { useTranslation } from 'react-i18next'
import { useLanguageStore } from '@/widgets/LanguageSelect/model/store'
import { createAutocompleteOptions, createTranslatedSelectOptions } from '@/shared/lib/utils'
import { useDebounce } from '@/shared/lib/useDebounce'
import { useSearchUsersByName } from '@/entities/User/api/user-api'
import { useSearchResidentialsByText } from '@/entities/Residential/api/residential-api'
import { useSearchContactsByName } from '@/entities/Contact/api/contact-api'

export const useCreateClientForm = (onCloseModal: () => void) => {
  const { language } = useLanguageStore()
  const [searchUser, setSearchUser] = useState<string>('')
  const [searchContact, setSearchContact] = useState<string>('')
  const [searchResidential, setSearchResidential] = useState<string>('')
  const [initialValues, setInitialValues] = useState<CreateClientFormState>(
    () => createClientFormDefault,
  )

  const debouncedSearchUser = useDebounce(searchUser, 500)
  const debouncedSearchContact = useDebounce(searchContact, 500)
  const debouncedSearchResidential = useDebounce(searchResidential, 500)

  const { data: users, isLoading: isLoadingUsers } = useSearchUsersByName(debouncedSearchUser)
  const { data: contacts, isLoading: isLoadingContacts } =
    useSearchContactsByName(debouncedSearchContact)
  const { data: residentials, isLoading: isLoadingResidentials } = useSearchResidentialsByText(
    language,
    debouncedSearchResidential,
  )

  const { t } = useTranslation(['common'])
  const {
    mutateAsync,
    isPending: isLoadingCreateClient,
    isSuccess: isSuccessCreateClient,
  } = useCreateClientApi()

  const submitForm: FormikConfig<CreateClientFormState>['onSubmit'] = async values => {
    try {
      await mutateAsync(values)
      onCloseModal()
    } catch (error) {
      errorHandler(error, t('common:notification_messages.create_client_error'))
    }
  }

  useEffect(() => {
    if (isSuccessCreateClient) {
      notification.success(
        t('common:notification_titles.success'),
        t('common:notification_messages.create_client_success'),
      )
    }
  }, [isSuccessCreateClient])

  const userOptions = (users?.data || []).map(user => ({
    value: String(user.id),
    label: user.fullname as string,
  }))

  const contactOptions = (contacts?.data || []).map(contact => ({
    value: String(contact.id),
    label: contact.fullname as string,
  }))

  const residentialsOptions = createAutocompleteOptions(residentials?.data, language)

  return {
    initialValues,
    residentialsOptions,
    contactOptions,
    userOptions,
    isLoadingCreateClient,
    submitForm,
    isLoadingUsers,
    isLoadingContacts,
    isLoadingResidentials,
    searchUser,
    searchContact,
    setSearchUser,
    setSearchContact,
    createClientFormDefault,
    searchResidential,
    setSearchResidential,
  }
}

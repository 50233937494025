import * as Yup from 'yup'
import { EditResidentialAmenityFormState } from '../model/types'
import { LanguageCode, LanguageLabel } from '@/widgets/LanguageSelect/lib/consts'
import { EditResidentialAmenityEN } from '../ui/Tabs/EditResidentialAmenityEN'
import { EditResidentialAmenityRU } from '../ui/Tabs/EditResidentialAmenityRU'
import { EditResidentialAmenityTR } from '../ui/Tabs/EditResidentialAmenityTR'
import { TFunction } from 'i18next'

export const editResidentialAmenityFormScheme = (t: TFunction) => {
  return Yup.object({
    translations: Yup.object({
      tr: Yup.object({
        name: Yup.string()
          .max(127, t('common:validation_messages.max', { count: 127 }))
          .required(t('common:validation_messages.required')),
      }),
      en: Yup.object({
        name: Yup.string().max(127, t('common:validation_messages.max', { count: 127 })),
      }),
      ru: Yup.object({
        name: Yup.string().max(127, t('common:validation_messages.max', { count: 127 })),
      }),
    }),
  })
}

export const editResidentialAmenityFormDefault: EditResidentialAmenityFormState = {
  id: 0,
  has_ai_translation: false,
  pic_url: '',
  translations: {
    tr: { name: '', id: null, ai_translated: false },
    en: { name: '', id: null, ai_translated: false },
    ru: { name: '', id: null, ai_translated: false },
  },
}

export const editAmenityFormTabsConfig = (isLoadingEditResidentialAmenity: boolean) => [
  {
    title: LanguageLabel.tr,
    value: LanguageCode.tr,
    component: EditResidentialAmenityTR,
    props: {
      isLoadingEditResidentialAmenity,
    },
  },
  {
    title: LanguageLabel.en,
    value: LanguageCode.en,
    component: EditResidentialAmenityEN,
    props: {
      isLoadingEditResidentialAmenity,
    },
  },
  {
    title: LanguageLabel.ru,
    value: LanguageCode.ru,
    component: EditResidentialAmenityRU,
    props: {
      isLoadingEditResidentialAmenity,
    },
  },
]

import { useCallback, useEffect, useState } from 'react'
import { createResidentialFormDefault } from './consts'
import { CreateResidentialFormState } from '../model/types'
import { FormikConfig, FormikErrors } from 'formik'
import { notification } from '@/shared/helpers/notificationMessages'
import {
  createAutocompleteOptions,
  createTranslatedSelectOptions,
  hasAiTranslation,
} from '@/shared/lib/utils'
import { useUserList, useUserStore } from '@/entities/User'
import { useAddressesWithoutResidentials } from '@/entities/Address'
import { useCreateResidentialApi } from '../api/create-residential-form-api'
import { ImagableType } from '@/shared/api/topli/TopliApi'
import { useResidentialAmenitiesByPage } from '@/entities/Residential'
import { errorHandler } from '@/shared/lib/errorHandler'
import { Coords } from 'google-map-react'
import { type CreateAddressFormState } from '@/features/Address/CreateAddressForm/model/types'
import { AddressInfo, useAddress } from '@/shared/ui/Map'
import { createAddressFormDefault } from '@/features/Address/CreateAddressForm/lib/consts'
import { getExistedProperties } from '@/shared/lib/getExistedProperties'
import { useCreateAddressApi } from '@/features/Address/CreateAddressForm'
import { useTranslation } from 'react-i18next'
import { useLanguageStore } from '@/widgets/LanguageSelect/model/store'
import { initializeTranslationsForCreation } from '@/shared/lib/initializeTranslationsForCreation'
import { useDebounce } from '@/shared/lib/useDebounce'
import { useSearchCompaniesByName } from '@/entities/Company/api/company-api'

export const useCreateResidentialForm = (onCloseModal: () => void) => {
  const { getAddressInfoByCoords } = useAddress()
  const { language } = useLanguageStore()
  const { data: managers, isLoading: isLoadingManagers } = useUserList({ is_manager: true })
  const { data: contentManagers, isLoading: isLoadingContentManagers } = useUserList({
    is_content_manager: true,
  })
  const { data: addresses, isLoading: isLoadingAddresses } = useAddressesWithoutResidentials()
  const { data: amenities, isLoading: isLoadingAmenities } = useResidentialAmenitiesByPage(
    1000,
    0,
    language,
  )
  const { user } = useUserStore()
  const [autoSettedFields, setAutoSettedFields] = useState<(keyof CreateAddressFormState)[]>([])
  const [newAddress, setNewAddress] = useState<CreateAddressFormState>(
    () => createAddressFormDefault,
  )
  const [coords, setCoords] = useState<Coords | null>(null)

  const [isOpen, setIsOpen] = useState(false)
  const [searchCompany, setSearchCompany] = useState<string>('')
  const debouncedSearchValue = useDebounce(searchCompany, 500)
  const { data: companies, isLoading: isLoadingCompanies } = useSearchCompaniesByName(
    language,
    debouncedSearchValue,
  )

  const [initialValues, setInitialValues] = useState<CreateResidentialFormState>(
    () => createResidentialFormDefault,
  )
  const { t } = useTranslation(['common'])

  const { mutateAsync, isPending: isLoadingCreateResidential } = useCreateResidentialApi(
    ImagableType.Residential,
  )

  const {
    mutateAsync: mutateAsyncCreateAddress,
    isPending: isLoadingCreateAddress,
    isSuccess: isSuccessCreateAddress,
    data: newAddressData,
  } = useCreateAddressApi()

  const submitForm: FormikConfig<CreateResidentialFormState>['onSubmit'] = async values => {
    const { translations, ...restValues } = values
    const updatedTranslations = initializeTranslationsForCreation(translations)
    const hasAiTranslationFlag = hasAiTranslation(updatedTranslations)

    const updatedValues = {
      ...restValues,
      has_ai_translation: hasAiTranslationFlag,
      translations: updatedTranslations,
    }

    onCloseModal()
    notification.loading(
      t('common:notification_titles.please_wait'),
      t('common:notification_messages.create_residential_in_progress'),
    )

    try {
      await mutateAsync(updatedValues)
      notification.success(
        t('common:notification_titles.success'),
        t('common:notification_messages.create_residential_success'),
      )
    } catch (error) {
      errorHandler(error, t('common:notification_messages.create_residential_error'))
    }
  }

  const handleSaveAddress = async (
    cb: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined,
    ) => Promise<void | FormikErrors<any>>,
  ) => {
    try {
      const newAddressResponse = await mutateAsyncCreateAddress(newAddress)
      cb('address_id', newAddressResponse?.id)
      setIsOpen(false)
    } catch (error) {
      errorHandler(error, t('common:notification_messages.create_address_error'))
    }
  }

  useEffect(() => {
    if (user) {
      setInitialValues(preValues => ({
        ...preValues,
        content_manager_id: user.id,
      }))
    }
  }, [user])

  useEffect(() => {
    if (coords === null) return

    const setAddressFormData = async (_coords: Coords) => {
      const address = await getAddressInfoByCoords(_coords)
      if (address === null) {
        setNewAddress(createAddressFormDefault)
        return
      }

      const addressInfo: Partial<AddressInfo> = {
        ...address,
        gid: address?.place_id ?? undefined,
      }
      setNewAddress(prevValues => {
        const {
          administrative_area_level_1,
          administrative_area_level_2,
          formatted,
          country,
          gid,
          locality,
          plus_code,
          postal_code,
          route,
          street_number,
        } = addressInfo
        return {
          ...prevValues,
          administrative_area_level_1: administrative_area_level_1 ?? '',
          administrative_area_level_2: administrative_area_level_2 ?? '',
          formatted,
          country: country ?? '',
          gid,
          locality: locality ?? '',
          plus_code: plus_code ?? '',
          postal_code: postal_code ?? '',
          route: route ?? '',
          street_number: street_number ?? '',
          lat: _coords.lat,
          lng: _coords.lng,
        }
      })
      setAutoSettedFields(getExistedProperties(addressInfo as CreateAddressFormState))
    }
    setAddressFormData(coords)
  }, [coords, getAddressInfoByCoords])

  const handleSetLoc = useCallback((coords: Coords | null) => {
    if (coords === null) return
    setCoords({ lat: coords.lat, lng: coords.lng })
  }, [])

  const handleCreateNewAddress = () => setIsOpen(true)

  const companiesOptions = createAutocompleteOptions(companies?.data, language)

  const managerOptions = (managers?.data || []).map(user => ({
    value: user.id,
    label: user.fullname as string,
  }))

  const contentManagerOptions = (contentManagers?.data || []).map(user => ({
    value: user.id,
    label: user.fullname as string,
  }))

  const addressOptions = (addresses?.data || []).map(address => ({
    value: address.id,
    label: address.formatted,
  }))

  const amenitiesOptions = createTranslatedSelectOptions(amenities?.data)

  return {
    initialValues,
    companiesOptions,
    amenitiesOptions,
    managerOptions,
    contentManagerOptions,
    addressOptions,
    addresses: addresses?.data,
    isLoadingAddresses,
    isLoadingCompanies,
    isLoadingManagers,
    isLoadingContentManagers,
    isLoadingCreateResidential,
    isLoadingAmenities,
    isLoadingCreateAddress,
    coords,
    isOpen,
    handleSetLoc,
    setIsOpen,
    submitForm,
    handleSaveAddress,
    handleCreateNewAddress,
    searchCompany,
    setSearchCompany,
    createResidentialFormDefault,
  }
}

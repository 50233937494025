import React from 'react'

type IconProps = {
  iconClass: string
  size?: 'sm' | 'md' | 'lg' | 'xl'
  color?: string
}

const sizeClasses = {
  sm: 'text-sm',
  md: 'text-base',
  lg: 'text-lg',
  xl: 'text-xl',
}

export const FontAwesome: React.FC<IconProps> = ({
  iconClass,
  size = 'xl',
  color = 'text-gray-500',
}) => {
  return <i className={`${iconClass} ${sizeClasses[size]} ${color}`} style={{ color: color }} />
}

import { Class, ParkingType, Purpose } from '@/shared/api/topli/generated/Api'
import { CreateResidentialFormState } from '../model/types'
import * as Yup from 'yup'
import { LanguageCode, LanguageLabel } from '@/widgets/LanguageSelect/lib/consts'
import { CreateResidentialFormEN } from '../ui/Tabs/CreateResidentialFormEN'
import { CreateResidentialFormRU } from '../ui/Tabs/CreateResidentialFormRU'
import { CreateResidentialFormTR } from '../ui/Tabs/CreateResidentialFormTR'
import { TFunction } from 'i18next'

export const createResidentialFormDefault: CreateResidentialFormState = {
  activity: true,
  polygon: [],
  delivery_date: '',
  done: false,
  video_url: '',
  clas: null,
  purpose: [],
  project_url: '',
  hide_price: false,
  area: 0,
  unitcount: 0,
  fee: 0,
  car_unitcount: 0,
  parking_type: null,
  googledrive_url: '',
  googledrive_company_url: '',
  company_id: 0,
  content_manager_id: 0,
  manager_id: 0,
  address_id: null,
  amenities: [],
  has_ai_translation: false,
  citizenship: true,
  title_deed: 4,
  vat: 18,
  downpayment: 100,
  duration: 12,
  translations: {
    tr: { name: '', description: '', ai_translated: false },
    en: { name: '', description: '', ai_translated: false },
    ru: { name: '', description: '', ai_translated: false },
  },
}

export const createResidentialFormSchema = (t: TFunction) => {
  return Yup.object({
    fee: Yup.number()
      .min(0, t('common:validation_messages.min', { count: 0 }))
      .max(99, t('common:validation_messages.value_must_be_less', { count: 100 })),
    vat: Yup.number()
      .min(0, t('common:validation_messages.min', { count: 0 }))
      .max(99, t('common:validation_messages.value_must_be_less', { count: 100 })),
    title_deed: Yup.number()
      .min(0, t('common:validation_messages.min', { count: 0 }))
      .max(99, t('common:validation_messages.value_must_be_less', { count: 100 })),
    downpayment: Yup.number()
      .min(0, t('common:validation_messages.min', { count: 0 }))
      .max(999, t('common:validation_messages.value_must_be_less', { count: 1000 })),
    duration: Yup.number()
      .min(0, t('common:validation_messages.min', { count: 0 }))
      .max(999, t('common:validation_messages.value_must_be_less', { count: 1000 })),
    activity: Yup.boolean(),
    polygon: Yup.array(Yup.array(Yup.number())),
    delivery_date: Yup.date(),
    done: Yup.boolean(),
    video_url: Yup.string().max(255, t('common:validation_messages.max', { count: 225 })),
    clas: Yup.mixed()
      .oneOf(Object.values(Class) as number[], t('common:validation_messages.required'))
      .nullable(),
    purpose: Yup.array()
      .of(
        Yup.mixed().oneOf(
          Object.values(Purpose) as number[],
          t('common:validation_messages.invalid_value'),
        ),
      )
      .ensure(),
    hide_price: Yup.boolean(),
    area: Yup.number().min(0, t('common:validation_messages.min', { count: 0 })),
    unitcount: Yup.number()
      .min(0, t('common:validation_messages.min', { count: 0 }))
      .required(),
    car_unitcount: Yup.number(),
    parking_type: Yup.mixed()
      .oneOf(Object.values(ParkingType) as number[], t('common:validation_messages.required'))
      .required(t('common:validation_messages.required')),
    googledrive_url: Yup.string().max(255, t('common:validation_messages.max', { count: 225 })),
    googledrive_company_url: Yup.string().max(
      255,
      t('common:validation_messages.max', { count: 225 }),
    ),
    company_id: Yup.number()
      .min(1, t('common:validation_messages.required'))
      .required(t('common:validation_messages.required')),
    address_id: Yup.number()
      .min(1, t('common:validation_messages.required'))
      .required(t('common:validation_messages.required')),
    content_manager_id: Yup.number()
      .min(1, t('common:validation_messages.required'))
      .required(t('common:validation_messages.required')),
    manager_id: Yup.number()
      .min(1, t('common:validation_messages.required'))
      .required(t('common:validation_messages.required')),
    project_url: Yup.string()
      .max(255, t('common:validation_messages.max', { count: 225 }))
      .nullable(),
    translations: Yup.object({
      tr: Yup.object({
        name: Yup.string()
          .max(127, t('common:validation_messages.max', { count: 127 }))
          .required(t('common:validation_messages.required')),
        description: Yup.string().max(4095, t('common:validation_messages.max', { count: 4095 })),
      }),
      en: Yup.object({
        name: Yup.string().max(127, t('common:validation_messages.max', { count: 127 })),
        description: Yup.string().max(4095, t('common:validation_messages.max', { count: 4095 })),
      }),
      ru: Yup.object({
        name: Yup.string().max(127, t('common:validation_messages.max', { count: 127 })),
        description: Yup.string().max(4095, t('common:validation_messages.max', { count: 4095 })),
      }),
    }),
  })
}

export const createResidentialFormTabsConfig = (isLoadingCreateResidential: boolean) => [
  {
    title: LanguageLabel.tr,
    value: LanguageCode.tr,
    component: CreateResidentialFormTR,
    props: {
      isLoadingCreateResidential,
    },
  },
  {
    title: LanguageLabel.en,
    value: LanguageCode.en,
    component: CreateResidentialFormEN,
    props: {
      isLoadingCreateResidential,
    },
  },
  {
    title: LanguageLabel.ru,
    value: LanguageCode.ru,
    component: CreateResidentialFormRU,
    props: {
      isLoadingCreateResidential,
    },
  },
]

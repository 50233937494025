import { useGoogleMapApiLoader } from '@/shared/ui/Map/hooks/useGoogleMapApiLoader'
import { useEffect, useState } from 'react'
import { Map } from '@shared/ui/Map/types.ts'
import { DEFAULT_CENTER, DEFAULT_ZOOM } from '@/shared/ui/Map/hooks/useMap/constants'

export const useMap = (
  options?: google.maps.MapOptions,
): [React.Dispatch<React.SetStateAction<HTMLDivElement | null>>, google.maps.Map | null] => {
  const loader = useGoogleMapApiLoader()

  const [map, setMap] = useState<Map | null>(null)
  const [mapElement, setMapElement] = useState<HTMLDivElement | null>(null)

  useEffect(() => {
    const getAndSetMap = async () => {
      if (mapElement === null) {
        setMap(null)
        return
      }

      const { Map } = await loader.importLibrary('maps')
      const _map = new Map(mapElement, {
        center: DEFAULT_CENTER,
        zoom: DEFAULT_ZOOM,
        mapId: import.meta.env.VITE_MAP_ID,
        fullscreenControl: false,
        mapTypeControl: true,
        streetViewControl: false,
        gestureHandling: 'greedy',
      })

      setMap(_map)
    }
    // noinspection JSIgnoredPromiseFromCall
    getAndSetMap()
  }, [loader, mapElement, options])

  return [setMapElement, map]
}

import { ActionIcon as MantineActionButton, Switch } from '@mantine/core'
import { ImageIcon, PenIcon, Trash, Copy } from 'lucide-react'
import { FC, ReactNode } from 'react'
import {
  MantineReactTable,
  useMantineReactTable,
  MRT_GlobalFilterTextInput,
  MRT_ShowHideColumnsButton,
} from 'mantine-react-table'
import { UnitListItem } from '@/shared/api/topli/TopliApi'
import { useUnitsTable } from '../../lib/use-units-table'
import { useTranslation } from 'react-i18next'
import { getLocalization } from '@/widgets/LanguageSelect/lib/consts'

type UnitsTableProps = {
  addButtonSlot: ReactNode
  onOpenEditModal: (row: UnitListItem) => void
  onOpenCopyModal: (row: UnitListItem) => void
  onOpenDeleteModal: (row: UnitListItem) => void
  onOpenPhotos: (row: UnitListItem) => void
  onOpenUploadedPhotos: (row: UnitListItem) => void
  onOpenFullDescription: (row: UnitListItem) => void
}

export const UnitsTable: FC<UnitsTableProps> = props => {
  const {
    addButtonSlot,
    onOpenEditModal,
    onOpenCopyModal,
    onOpenDeleteModal,
    onOpenPhotos,
    onOpenUploadedPhotos,
    onOpenFullDescription,
  } = props
  const {
    pagination,
    columns,
    sorting,
    fetchedUnits,
    totalRowCount,
    isErrorUnits,
    isFetchingUnits,
    isLoadingUnits,
    isShowLocalPrices,
    setPagination,
    setIsShowLocalPrices,
    setSorting,
    handleSearchChange,
  } = useUnitsTable(onOpenPhotos, onOpenFullDescription)

  const { t, i18n } = useTranslation(['unit'])
  const localization = getLocalization(i18n.language)

  const table = useMantineReactTable({
    columns,
    data: fetchedUnits,
    enableStickyHeader: false,
    enableColumnActions: false,
    enableEditing: false,
    manualPagination: true,
    manualSorting: true,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    localization,
    rowCount: totalRowCount,
    enableRowActions: true,
    onGlobalFilterChange: handleSearchChange,
    renderRowActions: ({ row }) => (
      <>
        <div className='flex flex-row space-x-1 pr-8'>
          <MantineActionButton
            onClick={() => onOpenEditModal(row.original)}
            className='text-blue bg-blue/10 hover:bg-blue hover:text-white rounded-full transition-all'
            variant='light'
          >
            <PenIcon size={16} />
          </MantineActionButton>
          <MantineActionButton
            onClick={() => onOpenCopyModal(row.original)}
            className='text-blue bg-blue/10 hover:bg-blue hover:text-white rounded-full transition-all'
            variant='light'
          >
            <Copy size={16} />
          </MantineActionButton>
          <MantineActionButton
            onClick={() => onOpenUploadedPhotos(row.original)}
            className='text-blue bg-blue/10 hover:bg-blue hover:text-white rounded-full transition-all'
            variant='light'
          >
            <ImageIcon size={16} />
          </MantineActionButton>
          <MantineActionButton
            onClick={() => onOpenDeleteModal(row.original)}
            className='text-red bg-red/10 hover:bg-red hover:text-white rounded-full transition-all'
            variant='light'
          >
            <Trash size={16} />
          </MantineActionButton>
        </div>
      </>
    ),
    renderTopToolbar: ({ table }) => {
      return (
        <div className='flex flex-row items-center justify-between mb-2'>
          <MRT_GlobalFilterTextInput table={table} />
          <div className='flex flex-row items-center space-x-2'>
            <Switch
              label={t('unit:show_is_local_price')}
              checked={isShowLocalPrices}
              onChange={event => setIsShowLocalPrices(event.currentTarget.checked)}
            />
            <MRT_ShowHideColumnsButton className='h-[2.5rem] w-[2.5rem] rounded-md' table={table} />
            {addButtonSlot}
          </div>
        </div>
      )
    },
    mantineToolbarAlertBannerProps: isErrorUnits
      ? {
          color: 'red',
          children: 'Error loading data',
        }
      : undefined,
    mantinePaperProps: {
      shadow: 'none',
      withBorder: false,
    },
    mantineTableProps: {
      sx: {
        tableLayout: 'auto',
      },
    },
    mantineTableBodyProps: {
      sx: {
        '&: td': {
          minWidth: 90,
        },
        '& tr:hover': {
          backgroundColor: '#FFFFFF',
        },
      },
    },
    mantineTableBodyRowProps: {
      className: 'hover:bg-[#F4FAFD]/10',
    },
    state: {
      isLoading: isLoadingUnits,
      pagination,
      sorting,
      showGlobalFilter: true,
      showAlertBanner: isErrorUnits,
      showProgressBars: isFetchingUnits,
    },
  })

  return <MantineReactTable table={table} />
}

import { CreateTranslations, EditTranslations } from '../types/ContentTranslations'

export const updateTranslationsForEditing = (
  currentTranslations: EditTranslations,
  initialTranslations: EditTranslations | null,
): EditTranslations => {
  return processTranslationUpdates(currentTranslations, initialTranslations)
}
export const updateTranslationsForCreation = (
  currentTranslations: CreateTranslations,
  initialTranslations: CreateTranslations | null,
): CreateTranslations => {
  return processTranslationUpdates(currentTranslations, initialTranslations)
}

export const processTranslationUpdates = <T extends CreateTranslations | EditTranslations>(
  currentTranslations: T,
  initialTranslations: Partial<T> | null,
): T => {
  const updatedTranslations: T = JSON.parse(JSON.stringify(currentTranslations))
  if (!initialTranslations) return currentTranslations

  Object.entries(currentTranslations).forEach(([lang, currentTranslationData]) => {
    const initialData = initialTranslations[lang]

    if (initialData?.ai_translated) {
      const hasChanges =
        currentTranslationData.name !== initialData.name ||
        currentTranslationData.description !== initialData.description

      updatedTranslations[lang] = hasChanges
        ? { ...currentTranslationData, ai_translated: false }
        : currentTranslationData
    } else {
      updatedTranslations[lang] = currentTranslationData
    }
  })
  return updatedTranslations
}

import {
  MantineReactTable,
  useMantineReactTable,
  MRT_GlobalFilterTextInput,
  MRT_ShowHideColumnsButton,
} from 'mantine-react-table'
import { ActionIcon as MantineActionButton } from '@mantine/core'
import { ImageIcon, PenIcon, Trash } from 'lucide-react'
import { useResidentialAmenitiesTable } from '../../lib/use-residential-amenities-table'
import { FC, ReactNode } from 'react'
import { ResidentialAmenityListItem } from '@/shared/api/topli/TopliApi'
import { useTranslation } from 'react-i18next'
import { getLocalization } from '@/widgets/LanguageSelect/lib/consts'

type ResidentialAmenitiesTableProps = {
  addButtonSlot: ReactNode
  onOpenEditModal: (row: ResidentialAmenityListItem) => void
  onOpenDeleteModal: (row: ResidentialAmenityListItem) => void
  onOpenUploadedPhotos: (row: ResidentialAmenityListItem) => void
  onOpenPhoto: (row: ResidentialAmenityListItem) => void
  onDeletePhoto: (row: ResidentialAmenityListItem) => void
}

export const ResidentialAmenitiesTable: FC<ResidentialAmenitiesTableProps> = props => {
  const { addButtonSlot, onOpenEditModal, onOpenDeleteModal, onOpenUploadedPhotos, onOpenPhoto } =
    props
  const {
    pagination,
    sorting,
    columns,
    fetchedResidentialAmenities,
    totalRowCount,
    isErrorResidentialAmenities,
    isFetchingResidentialAmenities,
    isLoadingResidentialAmenities,
    setSorting,
    setPagination,
    handleSearchChange,
  } = useResidentialAmenitiesTable()
  const { t, i18n } = useTranslation()
  const localization = getLocalization(i18n.language)

  const table = useMantineReactTable({
    columns,
    data: fetchedResidentialAmenities,
    enableStickyHeader: false,
    enableEditing: true,
    manualPagination: true,
    enableColumnActions: false,
    manualSorting: true,
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    rowCount: totalRowCount,
    positionActionsColumn: 'first',
    localization,
    enableGlobalFilter: true,
    onGlobalFilterChange: handleSearchChange,
    renderRowActions: ({ row }) => (
      <div className='flex flex-row space-x-1 pr-8'>
        <MantineActionButton
          onClick={() => onOpenEditModal(row.original)}
          className='text-blue bg-blue/10 hover:bg-blue hover:text-white rounded-full transition-all'
          variant='light'
        >
          <PenIcon size={16} />
        </MantineActionButton>
        {/* <MantineActionButton
          onClick={() => onOpenUploadedPhotos(row.original)}
          className='text-blue bg-blue/10 hover:bg-blue hover:text-white rounded-full transition-all'
          variant='light'
        >
          <ImageIcon size={16} />
        </MantineActionButton> */}
        <MantineActionButton
          onClick={() => onOpenDeleteModal(row.original)}
          className='text-red bg-red/10 hover:bg-red hover:text-white rounded-full transition-all'
          variant='light'
        >
          <Trash size={16} />
        </MantineActionButton>
      </div>
    ),
    renderTopToolbar: ({ table }) => {
      return (
        <div className='flex flex-row items-center justify-between mb-2'>
          <MRT_GlobalFilterTextInput table={table} />
          <div className='flex flex-row items-center space-x-2'>
            <MRT_ShowHideColumnsButton className='h-[2.5rem] w-[2.5rem] rounded-md' table={table} />
            {addButtonSlot}
          </div>
        </div>
      )
    },
    mantineToolbarAlertBannerProps: isErrorResidentialAmenities
      ? {
          color: 'red',
          children: 'Error loading data',
        }
      : undefined,
    mantinePaperProps: {
      shadow: 'none',
      withBorder: false,
    },
    mantineTableProps: {
      sx: {
        tableLayout: 'auto',
      },
    },
    mantineTableBodyProps: {
      sx: {
        '&: td': {
          minWidth: 90,
        },
        '& tr:hover': {
          backgroundColor: '#FFFFFF',
        },
      },
    },
    mantineTableBodyRowProps: {
      className: 'hover:bg-[#F4FAFD]/10',
    },
    state: {
      isLoading: isLoadingResidentialAmenities,
      pagination,
      sorting,
      showGlobalFilter: true,
      showAlertBanner: isErrorResidentialAmenities,
      showProgressBars: isFetchingResidentialAmenities,
    },
  })

  return <MantineReactTable table={table} />
}

import { Form, Formik } from 'formik'
import { FC } from 'react'
import { useCreateBuildingForm } from '../lib/use-create-building-form'
import {
  CheckBoxField,
  DateField,
  MultiSelectField,
  NumberField,
  SelectField,
} from '@/shared/ui/FormFields'
import { FieldFormPolygonDrawing } from './FieldFormPolygonDrawing'
import { Button } from '@/shared/ui/Button/Button'
import { Loader } from '@mantine/core'
import { createBuildingFormSchema, createBuildingFormTabsConfig } from '../lib/consts'
import { useTranslatedEnums } from '@/shared/helpers/apiEnumTypesOptions'
import { useTranslation } from 'react-i18next'
import LanguageTabs from '@/shared/ui/LanguageTabs/LanguageTabs'
import { InputAutocompleteField } from '@/shared/ui/Input/InputAutocompleteField'

type CreateBuildingFormProps = {
  selectResidentialId?: number
  onCloseModal: () => void
}

export const CreateBuildingForm: FC<CreateBuildingFormProps> = props => {
  const { selectResidentialId, onCloseModal } = props
  const { t } = useTranslation(['building', 'common'])
  const {
    residentials,
    initialValues,
    isLoadingCreateBuilding,
    submitForm,
    searchValue,
    setSearchValue,
    isLoadingResidentials,
    residentialsOptions,
    createBuildingFormDefault,
  } = useCreateBuildingForm(onCloseModal, selectResidentialId)
  const { parkingOptions, accommodationTypeOptions } = useTranslatedEnums()

  const languageTabs = createBuildingFormTabsConfig(isLoadingCreateBuilding)

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submitForm}
      enableReinitialize
      validationSchema={createBuildingFormSchema(t)}
    >
      {({ values }) => (
        <Form>
          <div className=' w-100 mx-[-2rem] pb-4 px-8 border-b border-white-600 bg-neutral-100'>
            <LanguageTabs config={languageTabs} />
          </div>
          <div className='flex flex-row items-center space-x-4 mb-4 pt-4'>
            <CheckBoxField
              name='activity'
              className='w-1/3'
              label={t('building:activity')}
              disabled={isLoadingCreateBuilding}
            />
            <CheckBoxField
              name='done'
              className='w-1/3'
              label={t('building:done')}
              disabled={isLoadingCreateBuilding}
            />
            <CheckBoxField
              name='hide_price'
              className='w-1/3'
              label={t('building:hide_price')}
              disabled={isLoadingCreateBuilding}
            />
          </div>
          <div className='flex flex-row items-center space-x-4'>
            <InputAutocompleteField
              name='residential_id'
              label={t('building:residential')}
              onChange={setSearchValue}
              value={searchValue}
              className='flex flex-col w-full mb-4'
              data={residentialsOptions}
              disabled={!!selectResidentialId || isLoadingCreateBuilding}
              isLoading={isLoadingResidentials}
              initialValue={createBuildingFormDefault.residential_id}
              required
            />
          </div>
          <div className='flex flex-row items-center mb-2'>
            <div className='text-sm font-medium mr-2'>{t('building:highlight_building')}:</div>
          </div>
          <div className='flex flex-row items-center mb-4'>
            <FieldFormPolygonDrawing
              name='area'
              residentials={residentials}
              disabled={!values.residential_id}
              className='flex flex-row items-center gap-4'
            />
          </div>
          <div className='flex flex-row items-center space-x-4'>
            <NumberField
              name='unitcount'
              label={t('building:unit_count')}
              className='flex flex-col w-full mb-4'
              disabled={isLoadingCreateBuilding}
              min={0}
            />
            <NumberField
              name='car_unitcount'
              label={t('building:car_unit_count')}
              className='flex flex-col w-full mb-4'
              disabled={isLoadingCreateBuilding}
              min={0}
            />
          </div>
          <div className='flex flex-row items-center space-x-4'>
            <MultiSelectField
              name='type'
              label={t('common:library_items.accommodation_type.sing')}
              options={accommodationTypeOptions}
              required
              className='flex flex-col w-full mb-4'
              disabled={isLoadingCreateBuilding}
            />
            <SelectField
              name='parking_type'
              label={t('common:library_items.parking_type.sing')}
              options={parkingOptions}
              className='flex flex-col w-full mb-4'
              disabled={isLoadingCreateBuilding}
            />
          </div>
          <div className='flex flex-row items-center space-x-4'>
            <NumberField
              name='nol'
              label={t('building:number_of_levels')}
              className='flex flex-col w-full mb-4'
              disabled={isLoadingCreateBuilding}
              min={0}
              required
            />
            <DateField
              name='delivery_date'
              label={t('building:delivery_date')}
              className='flex flex-col w-full mb-4'
              disabled={isLoadingCreateBuilding}
            />
          </div>
          <div className='flex flex-row w-full'>
            <Button type='submit' size='md' color='base' disabled={isLoadingCreateBuilding}>
              {isLoadingCreateBuilding ? (
                <Loader size='xs' color='white' />
              ) : (
                t('building:buttons.add_building')
              )}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

import { useEffect, useState } from 'react'
import { CreateCompanyFormState } from '../model/types'
import { createCompanyFormDefault } from './consts'
import { FormikConfig } from 'formik'
import { useCreateCompanyApi } from '@/features/Companies/CreateCompanyForm'
import { notification } from '@/shared/helpers/notificationMessages'
import { errorHandler } from '@/shared/lib/errorHandler'
import { useTranslation } from 'react-i18next'
import { initializeTranslationsForCreation } from '@/shared/lib/initializeTranslationsForCreation'
import { hasAiTranslation } from '@/shared/lib/utils'
import { useDebounce } from '@/shared/lib/useDebounce'
import { useSearchUsersByName } from '@/entities/User/api/user-api'

export const useCreateCompanyForm = (onCloseModal: () => void) => {
  const [searchValue, setSearchValue] = useState<string>('')
  const [initialValues] = useState<CreateCompanyFormState>(() => createCompanyFormDefault)

  const debouncedSearchValue = useDebounce(searchValue, 500)

  const { mutateAsync, isPending: isLoadingCreateCompany } = useCreateCompanyApi()
  const { t } = useTranslation(['common'])

  const { data: users, isLoading: isLoadingUsers } = useSearchUsersByName(debouncedSearchValue)
  const submitForm: FormikConfig<CreateCompanyFormState>['onSubmit'] = async values => {
    const { translations, ...restValues } = values

    const updatedTranslations = initializeTranslationsForCreation(translations)
    const hasAiTranslationFlag = hasAiTranslation(updatedTranslations)

    const updatedValues = {
      ...restValues,
      has_ai_translation: hasAiTranslationFlag,
      translations: updatedTranslations,
    }

    onCloseModal()
    notification.loading(
      t('common:notification_titles.please_wait'),
      t('common:notification_messages.create_company_in_progress'),
    )

    try {
      await mutateAsync(updatedValues)
      notification.success(
        t('common:notification_titles.success'),
        t('common:notification_messages.create_company_success'),
      )
    } catch (error) {
      errorHandler(error, t('notification_messages.create_company_error'))
    }
  }

  const userListOptions = (users?.data || []).map(user => ({
    label: user.fullname as string,
    value: String(user.id),
  }))

  return {
    initialValues,
    userListOptions,
    isLoadingCreateCompany,
    submitForm,
    searchValue,
    setSearchValue,
    isLoadingUsers,
    createCompanyFormDefault,
  }
}

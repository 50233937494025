import { useState } from 'react'
import { ImagableType, UnitListItem } from '@/shared/api/topli/TopliApi.ts'
import { CreateUnitModal } from './Modals/CreateUnitModal.tsx'
import { EditUnitModal } from './Modals/EditUnitModal.tsx'
import { DeleteUnitAlert } from '@/features/Unit/DeleteUnitAlert'
import { UnitsTable } from './UnitsTable/UnitsTable.tsx'
import { Button } from '@/shared/ui/Button/Button.tsx'
import { PlusCircleIcon } from 'lucide-react'
import { PhotoOwnerCaption, PhotosCarousel } from '@/entities/Photos'
import { EditUploadedPhotosModal } from '@/entities/Photos'
import { EditPhotoCropModal } from '@/features/Photos/EditPhotoCropModal'
import { currentUnitByIdQueryKey, unitListsByPageQueryKey } from '@/entities/Unit'
import { DeletePhotoAlert } from '@/features/Photos/DeletePhotoAlert'
import { UploadPhotosForm } from '@/features/Photos/UploadPhotosForm'
import { FullTextDescriptionModal } from '@/shared/ui/ModalDialogs/index.ts'
import { useTranslation } from 'react-i18next'
import { getCurrentTranslation } from '@/shared/lib/utils.ts'
import { useLanguageStore } from '@/widgets/LanguageSelect/model/store.ts'

export const UnitsView = () => {
  const { language } = useLanguageStore()
  const [currentTable, setCurrentTable] = useState<UnitListItem | null>(null)
  const [createDialogOpen, setCreateDialogOpen] = useState(false)
  const [editDialogOpen, setEditDialogOpen] = useState(false)
  const [copyDialogOpen, setCopyDialogOpen] = useState(false)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [showPhotosDialogOpen, setPhotosDialogOpen] = useState(false)
  const [openUploadedPhotosDialog, setOpenUploadedPhotosDialog] = useState(false)
  const [openEditCropPhotoDialog, setOpenEditCropPhotoDialog] = useState(false)
  const [openDeletePhotoDialog, setOpenDeletePhotoDialog] = useState(false)
  const [selectPhotoId, setSelectedPhotoId] = useState<number | null>(null)
  const [showMoreDialogOpen, setShowMoreDialogOpen] = useState(false)
  const [lastImageCropTimestamp, setLastImageCropTimestamp] = useState<number | null>(null)
  const { t } = useTranslation(['unit'])

  const handleCloseModal = () => {
    setCurrentTable(null)
    setSelectedPhotoId(null)
    setEditDialogOpen(false)
    setCopyDialogOpen(false)
    setCreateDialogOpen(false)
    setPhotosDialogOpen(false)
    setOpenUploadedPhotosDialog(false)
    setOpenEditCropPhotoDialog(false)
    setOpenDeletePhotoDialog(false)
  }

  const handleCloseCropModal = () => {
    setSelectedPhotoId(null)
    setOpenEditCropPhotoDialog(false)
  }

  const handleCloseDeletePhotoModal = () => {
    setSelectedPhotoId(null)
  }

  const handleOpenEditModal = (unit: UnitListItem) => {
    setCurrentTable(unit)
    setEditDialogOpen(true)
  }

  const handleOpenCopyModal = (unit: UnitListItem) => {
    setCurrentTable(unit)
    setCopyDialogOpen(true)
  }

  const handleOpenDeleteModal = (unit: UnitListItem) => {
    setCurrentTable(unit)
    setDeleteDialogOpen(true)
  }

  const handleOpenPhotos = (unit: UnitListItem) => {
    setCurrentTable(unit)
    setPhotosDialogOpen(true)
  }

  const handleOpenUploadedPhotos = (unit: UnitListItem) => {
    setCurrentTable(unit)
    setOpenUploadedPhotosDialog(true)
  }

  const handleOpenEditCropPhoto = (photoId: number) => {
    setSelectedPhotoId(photoId)
    setOpenEditCropPhotoDialog(true)
  }

  const handleDeletePhoto = (photoId: number) => {
    setSelectedPhotoId(photoId)
    setOpenDeletePhotoDialog(true)
  }

  const handleOpenShowMoreModal = (unit: UnitListItem) => {
    const translatedDescription = getCurrentTranslation(unit, language, 'description')
    setCurrentTable({
      ...unit,
      description: translatedDescription,
    })
    setShowMoreDialogOpen(true)
  }

  return (
    <>
      <div className='flex flex-col md:w-full w-[calc(100%+2.5rem)] md:mx-0 mx-[-1.25rem] bg-white rounded-[1.25rem] shadow-navbar p-4'>
        <UnitsTable
          onOpenEditModal={handleOpenEditModal}
          onOpenCopyModal={handleOpenCopyModal}
          onOpenDeleteModal={handleOpenDeleteModal}
          onOpenPhotos={handleOpenPhotos}
          onOpenUploadedPhotos={handleOpenUploadedPhotos}
          onOpenFullDescription={handleOpenShowMoreModal}
          addButtonSlot={
            <Button
              variant='primary'
              color='base'
              size='md'
              onClick={() => setCreateDialogOpen(true)}
              leftIcon={<PlusCircleIcon size={16} />}
            >
              {t('unit:buttons.new_unit')}
            </Button>
          }
        />
      </div>
      <CreateUnitModal
        dialogProps={{ open: createDialogOpen, onOpenChange: setCreateDialogOpen }}
        onCloseModal={handleCloseModal}
      />
      {currentTable && (
        <EditUnitModal
          dialogProps={{ open: editDialogOpen, onOpenChange: setEditDialogOpen }}
          currentUnitId={currentTable.id}
          onCloseModal={handleCloseModal}
        />
      )}
      {currentTable && (
        <CreateUnitModal
          dialogProps={{ open: copyDialogOpen, onOpenChange: setCopyDialogOpen }}
          currentUnitId={currentTable.id}
          onCloseModal={handleCloseModal}
        />
      )}
      {selectPhotoId && openDeletePhotoDialog && (
        <DeletePhotoAlert
          open={openDeletePhotoDialog}
          onOpenChange={setOpenDeletePhotoDialog}
          selectPhotoId={selectPhotoId}
          queryKeyForUpdate={PhotoOwnerCaption.Unit}
          onCloseModal={handleCloseDeletePhotoModal}
        />
      )}
      {currentTable && selectPhotoId && (
        <EditPhotoCropModal
          dialogProps={{
            open: openEditCropPhotoDialog,
            onOpenChange: setOpenEditCropPhotoDialog,
          }}
          onCloseModal={() => {
            handleCloseCropModal()
            setLastImageCropTimestamp(new Date().getTime())
          }}
          selectedPhotoId={selectPhotoId}
          queryKeyForObjectUpdate={currentUnitByIdQueryKey}
          queryKeyForPageUpdate={unitListsByPageQueryKey}
          obj_id={currentTable.id}
          owner={PhotoOwnerCaption.Unit}
        />
      )}
      {currentTable && (
        <EditUploadedPhotosModal
          dialogProps={{
            open: openUploadedPhotosDialog,
            onOpenChange: setOpenUploadedPhotosDialog,
          }}
          obj_id={currentTable.id}
          owner={PhotoOwnerCaption.Unit}
          lastImageCropTimestamp={lastImageCropTimestamp}
          uploadDropzoneSlot={
            <UploadPhotosForm
              obj_id={currentTable.id}
              ownerValue={ImagableType.Unit}
              queryKeyForUpdate={unitListsByPageQueryKey}
            />
          }
          onDeletePhoto={handleDeletePhoto}
          onOpenCropPhoto={handleOpenEditCropPhoto}
        />
      )}
      {currentTable && showPhotosDialogOpen && (
        <PhotosCarousel
          isOpened={showPhotosDialogOpen}
          obj_id={currentTable.id}
          owner={PhotoOwnerCaption.Unit}
          setIsOpened={setPhotosDialogOpen}
          onCloseModal={handleCloseModal}
          photos={currentTable?.photos || []}
        />
      )}
      {currentTable && (
        <DeleteUnitAlert
          open={deleteDialogOpen}
          onOpenChange={setDeleteDialogOpen}
          selectUnitId={currentTable.id}
          onCloseModal={handleCloseModal}
        />
      )}
      {currentTable && (
        <FullTextDescriptionModal
          dialogProps={{ open: showMoreDialogOpen, onOpenChange: setShowMoreDialogOpen }}
          description={currentTable?.description}
        />
      )}
    </>
  )
}

import { ModelTypes, topliApi } from '@/shared/api/topli/TopliApi'
import { EditUnitFormState, EditUnitResponse } from '../model/types'
import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query'
import { currentUnitByIdQueryKey, unitListsByPageQueryKey } from '@/entities/Unit/api/unit-api'
import { deletePrice, updatePrices } from '@/features/Price/EditPriceFormField'
import { errorHandler } from '@/shared/lib/errorHandler'
import { currentBuildingByIdQueryKey } from '@/entities/Building'
import { editTranslation } from '@/entities/ContentTranslation'
import { EditPriceFormState } from '@/features/Price/EditPriceFormField/model/types'

export const editUnit = async (state: EditUnitFormState): Promise<EditUnitResponse> => {
  const {
    id,
    prices,
    id_deleted_price,
    translations,
    building_id,
    level,
    building_level,
    ...restState
  } = state

  const isLastFloor = building_level === level

  const response = await topliApi.unit.update(id, {
    name: translations.tr.name,
    description: translations?.tr.description,
    last_floor: isLastFloor,
    level,
    building_id,
    ...restState,
  })
  const modifiedResponse = { ...response, prices, id_deleted_price }
  return modifiedResponse
}

export const useEditUnitApi = ({
  isEditFromTable = false,
}: { isEditFromTable?: boolean } = {}): UseMutationResult<
  EditUnitResponse,
  Error,
  EditUnitFormState
> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: state => editUnit(state),
    onSuccess: async (res, state) => {
      try {
        if (res.id_deleted_price.length > 0) {
          await Promise.all(res.id_deleted_price?.map(async price => await deletePrice(price)))
        }

        const prices: EditPriceFormState[] = res.prices.map(price => ({
          ...price,
          id: price.id,
          price: Number(price.price * 100),
          unit_id: res.id,
          main: true,
        }))
        await updatePrices(prices)

        if (!isEditFromTable) {
          await editTranslation(
            { translations: state.translations, modelType: ModelTypes.Unit },
            res.id,
          )
        }
      } catch (error) {
        errorHandler(error)
      }
    },
    onSettled: () => {
      Promise.all([
        queryClient.invalidateQueries({
          queryKey: [unitListsByPageQueryKey],
          refetchType: 'active',
        }),
        queryClient.invalidateQueries({
          queryKey: [currentUnitByIdQueryKey],
          refetchType: 'active',
        }),
        queryClient.invalidateQueries({
          queryKey: [currentBuildingByIdQueryKey],
          refetchType: 'active',
        }),
      ])
    },
  })
}

import { FC } from 'react'
import { Form, Formik } from 'formik'
import { useCreateUnitAmenityForm } from '../lib/use-create-unit-amenity-form'
import { createAmenityFormTabsConfig, createUnitAmenityFormScheme } from '../lib/consts'
import { Button } from '@/shared/ui/Button/Button'
import { Loader } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import LanguageTabs from '@/shared/ui/LanguageTabs/LanguageTabs'
import { TextField } from '@/shared/ui/FormFields'
import { FontAwesome } from '@/widgets/ui/font-awesome'

type CreateUnitAmenityFormProps = {
  selectUnitAmenityId?: number
  onCloseModal: () => void
}

export const CreateUnitAmenityForm: FC<CreateUnitAmenityFormProps> = props => {
  const { selectUnitAmenityId, onCloseModal } = props
  const { initialValues, isLoadingCreateUnitAmenity, submitForm } = useCreateUnitAmenityForm(
    onCloseModal,
    selectUnitAmenityId,
  )
  const { t } = useTranslation(['amenity'])
  const languageTabs = createAmenityFormTabsConfig(isLoadingCreateUnitAmenity)

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submitForm}
      validationSchema={createUnitAmenityFormScheme(t)}
      enableReinitialize
    >
      {({ values }) => (
        <Form>
          <div className=' w-100 mx-[-2rem] px-8'>
            <LanguageTabs config={languageTabs} />
          </div>
          <div className='flex flex-row items-center space-x-4'>
            <TextField
              name='pic_url'
              label={t('amenity:icon_class')}
              className='flex flex-col w-full mb-4'
              disabled={isLoadingCreateUnitAmenity}
              placeholder='fa-solid fa-mug-saucer'
            />
            {values.pic_url && (
              <div className='flex flex-col mt-1'>
                <FontAwesome iconClass={values.pic_url} />
              </div>
            )}
          </div>
          <div className='flex flex-row w-full'>
            <Button type='submit' size='md' color='base' disabled={isLoadingCreateUnitAmenity}>
              {isLoadingCreateUnitAmenity ? (
                <Loader size='xs' color='white' />
              ) : (
                t('amenity:buttons.add_amenity')
              )}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

import { FC } from 'react'
import { Button } from '@/shared/ui/Button/Button'
import { Form, Formik } from 'formik'
import { useCreateCompanyForm } from '../lib/use-create-company-form'
import {
  CheckBoxField,
  MultiSelectField,
  PhoneField,
  TextField,
  YearPickerField,
} from '@/shared/ui/FormFields'
import { createCompanyFormSchema, createCompanyFormTabsConfig } from '../lib/consts'
import { Loader } from '@mantine/core'
import { useTranslatedEnums } from '@/shared/helpers/apiEnumTypesOptions'
import { useTranslation } from 'react-i18next'
import LanguageTabs from '@/shared/ui/LanguageTabs/LanguageTabs'
import { InputAutocompleteField } from '@/shared/ui/Input/InputAutocompleteField'

type CreateCompanyFormProps = {
  onCloseModal: () => void
}

export const CreateCompanyForm: FC<CreateCompanyFormProps> = props => {
  const { onCloseModal } = props
  const { companyTypeOptions } = useTranslatedEnums()
  const { t } = useTranslation(['company', 'common'])
  const {
    initialValues,
    userListOptions,
    isLoadingCreateCompany,
    submitForm,
    searchValue,
    setSearchValue,
    isLoadingUsers,
    createCompanyFormDefault,
  } = useCreateCompanyForm(onCloseModal)

  const languageTabs = createCompanyFormTabsConfig(isLoadingCreateCompany)

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submitForm}
      validationSchema={createCompanyFormSchema(t)}
      enableReinitialize
    >
      <Form>
        <div className=' w-100 mx-[-2rem] pb-4 px-8 border-b border-white-600 bg-neutral-100'>
          <LanguageTabs config={languageTabs} />
        </div>
        <div className='flex flex-row items-center space-x-4 mb-4 pt-4'>
          <CheckBoxField
            name='featured'
            className='w-1/3'
            label={t('company:featured')}
            disabled={isLoadingCreateCompany}
          />
          <CheckBoxField
            name='only_on_topli'
            className='w-1/3'
            label={t('company:only_on_topli')}
            disabled={isLoadingCreateCompany}
          />
        </div>
        <div className='flex flex-row w-full space-x-4 pt-4'>
          <TextField
            name='email'
            type='email'
            label={t('company:email')}
            className='flex flex-col w-full mb-4'
            disabled={isLoadingCreateCompany}
            required
          />
        </div>
        <div className='flex flex-row w-full space-x-4'>
          <MultiSelectField
            name='type'
            label={t('common:library_items.company_type.sing')}
            options={companyTypeOptions}
            disabled={isLoadingCreateCompany}
            className='flex flex-col w-full mb-4'
            required
          />
          <YearPickerField
            name='year'
            label={t('company:year')}
            maxDate={new Date()}
            className='flex flex-col w-full mb-4'
            disabled={isLoadingCreateCompany}
          />
        </div>
        <div className='flex flex-row w-full space-x-4'>
          <TextField
            name='website'
            label={t('company:website')}
            className='flex flex-col w-full mb-4'
            disabled={isLoadingCreateCompany}
            required
          />
          <PhoneField
            name='phone'
            label={t('company:phone')}
            className='flex flex-col w-full mb-4'
            disabled={isLoadingCreateCompany}
            required
            dropDownClassName={'!left-[-74px]'}
          />
        </div>
        <div className='flex flex-row w-full space-x-4'>
          <InputAutocompleteField
            name='responsible_id'
            label={t('company:responsible')}
            onChange={setSearchValue}
            value={searchValue}
            className='flex flex-col w-full mb-4'
            data={userListOptions}
            disabled={isLoadingCreateCompany}
            isLoading={isLoadingUsers}
            initialValue={createCompanyFormDefault.responsible_id}
            required
          />
          <TextField
            name='address'
            label={t('company:address')}
            className='flex flex-col w-full mb-4'
            disabled={isLoadingCreateCompany}
            required
          />
        </div>
        <div className='flex flex-row w-full'>
          <Button type='submit' size='md' color='base' disabled={isLoadingCreateCompany}>
            {isLoadingCreateCompany ? (
              <Loader size='xs' color='white' />
            ) : (
              t('company:buttons.add_company')
            )}
          </Button>
        </div>
      </Form>
    </Formik>
  )
}

import { useQuery, useQueryClient } from '@tanstack/react-query'
import { topliApi } from '@/shared/api/topli/TopliApi'
import { useEffect } from 'react'

export const addressListsQueryKey = 'addressLists'
export const addressListWithoutResidentialsQueryKey = 'addressListWithoutResidentials'
export const addressListsByPageQueryKey = 'addressListsByPage'
export const searchAddressesQueryKey = 'searchAddresses'
export const selectedAddressQueryKey = 'selectedAddress'

export const fetchAddressList = async (params?: Record<string, any>) => {
  const response = await topliApi.address.list(params || {})
  return response
}

export const fetchAddressListByPage = async (
  sort: string | null,
  limit: number,
  offset: number,
  search: string,
) => {
  const response = await topliApi.address.list({
    limit,
    offset,
    sort,
    formatted__icontains: search,
  })
  return response
}

export const searchAddressByGoogle = async (q?: string) => {
  if (!q) return
  const response = await topliApi.googleApi.getAddrOpts({ query: q })
  return response
}

export const fetchAddressGoogleById = async (gid?: string | null) => {
  if (!gid) return
  const response = await topliApi.googleApi.getAddr(gid)
  return response
}

export const useAddresses = () =>
  useQuery({
    queryKey: [addressListsQueryKey],
    queryFn: () => fetchAddressList(),
  })

export const useAddressesWithoutResidentials = () =>
  useQuery({
    queryKey: [addressListWithoutResidentialsQueryKey, { residential__isnull: true }],
    queryFn: () => fetchAddressList({ residential__isnull: true }),
    staleTime: 0,
  })

export const useAddressesByPage = (
  sorting: string | null,
  limit: number,
  page: number,
  search: string,
) =>
  useQuery({
    queryKey: [addressListsByPageQueryKey, sorting, page, limit, search || ''],
    queryFn: () => fetchAddressListByPage(sorting, limit, page, search),
  })

export const useSearchAddressByGoogle = (search?: string) =>
  useQuery({
    queryKey: [searchAddressesQueryKey, search],
    queryFn: () => searchAddressByGoogle(search),
    enabled: !!search,
  })

export const useAddressGoogleById = (id: string | null) =>
  useQuery({
    queryKey: [selectedAddressQueryKey, id],
    queryFn: () => fetchAddressGoogleById(id),
    enabled: !!id,
  })

export const useSearchAddressByText = (text: string) => {
  const queryClient = useQueryClient()

  const data = useQuery({
    queryKey: [searchAddressesQueryKey, text],
    queryFn: () => searchAddressByGoogle(text),
    enabled: !!text,
  })

  useEffect(() => {
    if (text) {
      queryClient.invalidateQueries({
        queryKey: [searchAddressesQueryKey],
        refetchType: 'active',
      })
    }
  }, [text, queryClient])

  return data
}
